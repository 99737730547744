// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-success-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(235, 235, 235, 0.6);
  z-index: 9999;
  bottom: -100%;
  transition: 1s ease-in-out;
}
.request-success-container.active {
  bottom: 0;
}
.request-success-container .request-success-content {
  position: absolute;
  background-color: white;
  top: 60px;
  height: calc(100vh - 60px);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 20px 0px rgba(42, 42, 42, 0.1);
}
.request-success-container .request-success-content .close-button {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.request-success-container .request-success-content .close-button:hover {
  opacity: 0.8;
}
.request-success-container .request-success-content h1 {
  font-size: 24px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/SuccessModal/successModal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,eAAA;EACA,0CAAA;EACA,aAAA;EACA,aAAA;EACA,0BAAA;AACJ;AAAI;EACI,SAAA;AAER;AAAI;EACI,kBAAA;EACA,uBAAA;EACA,SAAA;EACA,0BAAA;EACA,4BAAA;EACA,6BAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kDAAA;AAER;AADQ;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,4BAAA;AAGZ;AAFY;EACI,YAAA;AAIhB;AADQ;EACI,eAAA;EACA,gBAAA;AAGZ","sourcesContent":[".request-success-container {\n    height: 100vh;\n    width: 100vw;\n    position: fixed;\n    background-color: rgba($color: #EBEBEB, $alpha: .6);\n    z-index: 9999;\n    bottom: -100%;\n    transition: 1s ease-in-out;\n    &.active {\n        bottom: 0;\n    }\n    .request-success-content {\n        position: absolute;\n        background-color: white;\n        top: 60px;\n        height: calc(100vh - 60px);\n        border-top-left-radius: 24px;\n        border-top-right-radius: 24px;\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        box-shadow: 0px 5px 20px 0px rgba(42, 42, 42, 0.10);\n        .close-button {\n            position: absolute;\n            top: 32px;\n            right: 32px;\n            cursor: pointer;\n            transition: .2s ease-in-out;\n            &:hover {\n                opacity: .8;\n            }\n        }\n        h1 {\n            font-size: 24px;\n            font-weight: 700;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
