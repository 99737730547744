import API from 'API'
import feedSlice from '../reducers/feed'

const { fetchWagglesSuccess, onScrollFetchWagglesSuccess, checkNewWagglesSuccess } = feedSlice.actions

const fetchWaggles = () => async dispatch => {
    const response = await API.feed.fetchWaggles({ offset: 0 });
    const payload = response.data;
    dispatch(fetchWagglesSuccess(payload));
}

const onScrollFetchWaggles = ({ offset }) => async dispatch => {
  const response = await API.feed.fetchWaggles({ offset });
  const payload = response.data;
  payload.is_end = false;
  payload.offset = offset
  if (payload.results.length < 1) {
    payload.is_end = true;
  }
  dispatch(onScrollFetchWagglesSuccess(payload));
}

const postWaggle = (data) => async dispatch => {
    await API.feed.postWaggle(data);
}

const deleteWaggle = (id) => async dispatch => {
    await API.feed.deleteWaggle(id);
}

const fetchListingWaggles = (listing) => async dispatch => {
  const response = await API.feed.fetchListingWaggles({ offset: 0, listing: listing });
  const payload = response.data;
  return payload
}

const fetchUsergWaggles = (user) => async dispatch => {
  const response = await API.feed.fetchUsergWaggles({ offset: 0, user: user });
  const payload = response.data;
  return payload
}

const checkNewWaggles = (data) => async dispatch => {
    const response = await API.feed.checkNewWaggles(data);
    const payload = response.data;
    dispatch(checkNewWagglesSuccess(payload));
    return payload;
};

const feedActions = {
    fetchWaggles,
    onScrollFetchWaggles,
    postWaggle,
    fetchListingWaggles,
    fetchUsergWaggles,
    checkNewWaggles,
    deleteWaggle
}


export default feedActions;