// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-agent-container {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invite-agent-container .header {
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 8px;
}
.invite-agent-container .header > p {
  margin: 0;
}
.invite-agent-container .header .back-button {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #2a2a2a;
  position: absolute;
  left: 0;
  transition: 0.2s ease-in-out;
}
.invite-agent-container .header .back-button:hover {
  color: var(--primary-color-1);
}
.invite-agent-container .form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 327px;
}`, "",{"version":3,"sources":["webpack://./src/routes/ShowingRequest/modules/InviteAgent/inviteAgent.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EACI,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;AAER;AADQ;EACI,SAAA;AAGZ;AADQ;EACI,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,OAAA;EACA,4BAAA;AAGZ;AAFY;EACI,6BAAA;AAIhB;AAAI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;AAER","sourcesContent":[".invite-agent-container {\r\n    padding-top: 80px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    .header {\r\n        flex-direction: column;\r\n        display: flex;\r\n        align-items: center;\r\n        position: relative;\r\n        width: 100%;\r\n        gap: 8px;\r\n        > p {\r\n            margin: 0;\r\n        }\r\n        .back-button {\r\n            cursor: pointer;\r\n            font-size: 16px;\r\n            font-weight: 700;\r\n            color: #2a2a2a;\r\n            position: absolute;\r\n            left: 0;\r\n            transition: .2s ease-in-out;\r\n            &:hover {\r\n                color: var(--primary-color-1);\r\n            }\r\n        }\r\n    }\r\n    .form-container {\r\n        display: flex;\r\n        flex-direction: column;\r\n        gap: 16px;\r\n        align-items: center;\r\n        width: 327px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
