
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// Redux
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Components
import Icons from "components/Icons";
import Seprator from "components/Separator";
// Assets
import './headerNavigation.scss'
import NoUser from 'assets/images/no_user.jpg'
import { checkImageUrl } from "utils/modules/Handlers";
import Talk from "talkjs";
import { Config } from "utils";
import Logo from "components/Logo";
import HeaderUserBlock from "components/HeaderUserBlock";
import PageWrapper from "components/PageWrapper";
import CustomContainer from "components/CustomContainer";


const HeaderNavigation = () => {
    const navigate = useNavigate()
    const [modal, setModal] = useState(false)
    const [activeItem, setActiveItem] = useState('/')
    const [unread, setUnread] = useState(0)
    const [session, setSession] = useState(null)
    const { pathname } = useLocation();
    const { user } = useSelector((state) => state.auth)
    const [talkLoaded, markTalkLoaded] = useState(false);

    useEffect(() => {
        const init = () => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (user.id && talkLoaded) {
                const currentUser = new Talk.User({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    photoUrl: checkImageUrl(user.profile_photo, NoUser),
                    role: user.user_type,
                });
                
                const sessionData = new Talk.Session({
                    appId: Config.TalkJS_APP_ID,
                    me: currentUser,
                });
                setSession(sessionData)
            }
        }

        init();
    }, [user, talkLoaded])

    useEffect(() => {
        if (session) {
            session.unreads.onChange((unreadConversations) => {
                const amountOfUnreads = unreadConversations.length;
                setUnread(amountOfUnreads)
            });
        }
    }, [session])

    useEffect(() => {
        if (pathname === "/") {
            setActiveItem("/")
        } else if (pathname.includes('/waggle')) {
            setActiveItem("/waggle")
        } else if (pathname.includes('/listings') || pathname.includes('/request')) {
            setActiveItem("/listings")
        } else if (pathname.includes('/main-calendar')) {
            setActiveItem("/main-calendar")
        } else if (pathname.includes('/messages')) {
            setActiveItem("/messages")
        } else if (pathname.includes('/showings')) {
            setActiveItem("/showings")
        } else if (pathname.includes('/me') || pathname.includes('/contacts') || pathname.includes('/bee-tier')) {
            setActiveItem("")
        }

    }, [pathname])
    

    const navigateTo = (item) => {
        navigate(`${item}`)
        setActiveItem(item)
    }

    return (
        <>
            <PageWrapper style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999, backgroundColor: 'white' }}>
                <CustomContainer style={{ borderBottom: '2px solid #F9FAFC' }}>
                    <header className="waggle-navigation" onClick={() => setModal(false)}>
                        {/* <div className="logo" onClick={() => navigateTo('/home')}>
                            <img src={SBLogo} alt="" />
                        </div> */}
                        <div className="logo-container">
                            <Logo type="mini" action={() => navigateTo('/home')} style={{ height: 34, width: 34, cursor: 'pointer' }} />
                        </div>
                        <div className="nav-items">
                            <ul>
                                {
                                    (user.user_type === 'agent' || user.user_type === 'mortgage_broker') &&
                                    <li data-name="Dashboard" className={`${activeItem === '/' && 'active'}`} onClick={() => navigateTo('/')}>
                                        <Icons.Dashboard color={`${activeItem === '/' ? '#FFF' : '#949494'}`} width={16.7} height={16.7}  />
                                    </li>
                                }
                                {
                                    (user.user_type === 'buyer' || user.user_type === 'seller') 
                                    ? <li data-name="Feed" className={`${activeItem === '/' && 'active'}`} onClick={() => navigateTo('/')}>
                                        <Icons.Waggle 
                                            color={`${activeItem === '/' ? '#FFF' : '#949494'}`} 
                                            
                                        />
                                    </li>
                                    : <li data-name="Feed" className={`${activeItem === '/waggle' && 'active'}`} onClick={() => navigateTo('/waggle')}>
                                        <Icons.Waggle 
                                            color={`${activeItem === '/waggle' ? '#FFF' : '#949494'}`} 
                                            width={18} height={20}
                                        />
                                    </li>
                                }
                                {
                                    (user.user_type === 'agent' || user.user_type === 'buyer' || user.user_type === 'seller') &&
                                    <li data-name="Search" className={`${activeItem === '/listings' && 'active'}`} onClick={() => navigateTo('/listings')}>
                                        <Icons.Search color={`${activeItem === '/listings' ? '#FFF' : '#949494'}`} width={22} height={22} />
                                    </li>
                                }
                                <li data-name="Messages" className={`${activeItem === '/messages' && 'active'} messages`} onClick={() => navigateTo('/messages')}>
                                    { unread > 0 && <p className="notif">{unread}</p> }
                                    <Icons.Message color={`${activeItem === '/messages' ? '#FFF' : '#949494'}`} />
                                </li>

                                {
                                    user.user_type !== 'mortgage_broker' &&
                                    <li data-name="Calendar" className={`${activeItem === '/main-calendar' && 'active'}`} onClick={() => navigateTo('/main-calendar')}>
                                        <Icons.Calendar color={`${activeItem === '/main-calendar' ? '#FFF' : '#949494'}`} />
                                    </li>
                                }

                                {
                                    (user.user_type === 'buyer' || user.user_type === 'seller') &&
                                    <li data-name="Showings" className={`${activeItem === '/showings' && 'active'}`} onClick={() => navigateTo('/showings')}>
                                        <Icons.Showing color={`${activeItem === '/showings' ? '#FFF' : '#949494'}`} />
                                    </li>
                                }
                                {/* <li data-name="Saved"><Icons.Save color="#6C767C" /></li> */}
                            </ul>
                        </div>
                        <HeaderUserBlock modal={modal} setModal={setModal} />
                    </header>
                </CustomContainer>
            </PageWrapper>
            <Seprator height={60} />
            <div onClick={() => setModal(false)}>
                <Outlet />
            </div>
        </>
    )
}


export default HeaderNavigation;