import { createSlice } from '@reduxjs/toolkit'

const feedSlice = createSlice({
  name: 'feed',
  initialState: {
    data: [],
    is_end: false,
    offset: 0,
    hasNewContent: false,
  },
  reducers: {
    fetchWagglesSuccess: (state, action) => {
      state.data = action.payload.results;
      state.offset = 0;
      state.is_end = false;
    },
    onScrollFetchWagglesSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.results];
      state.is_end = action.payload.is_end;
      state.offset = action.payload.offset;
    },
    checkNewWagglesSuccess: (state, action) => {
      state.hasNewContent = action.payload.hasNewContent;
    },
  },
});

export default feedSlice