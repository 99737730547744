import Footer from "components/Footer";
import RequestStepper from "components/RequestStepper";
import Separator from "components/Separator";
import ToastMessage from "components/ToastMessage";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import actions from "store/actions";
// Utils
import { getErrorMessage, inputChange } from 'utils/modules/Handlers';
import './inviteAgent.scss'
import PageWrapper from "components/PageWrapper";
import CustomContainer from "components/CustomContainer";
import CustomTextInput from "components/CustomTextInput";
import CustomButton from "components/CustomButton";

const InviteAgent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { id: listingID, mls } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
        user_type: {
            value: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePhone = (phone_number) => {
        let phone = `+1${phone_number}`
        return phone.match(/(^\+\d{11}(\d{2})?$)/);
    };

    const onSubmit = useCallback(async (event) => {
        const { email, first_name, last_name, phone_number } = form

        event.preventDefault();
        let isValid = true

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }

        if (first_name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
        }

        if (last_name.value === '') {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
        }

        if (validateEmail(email.value) && first_name.value && last_name.value) {
            isValid = true
        }

        if (phone_number.value) {
            if (!validatePhone(phone_number.value)) {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: '' })
                isValid = true
            }
        }
        if (isValid) {
            setIsLoading(true)
            try {
                const data = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    phone_number: phone_number.value ? `1${phone_number.value}` : '',
                }
                await dispatch(actions.buyer.inviteAgent(data)).then(async (res) => {
                    const body = {
                        showing: localStorage.getItem('showingId'),
                        buyer_agent: res.agent,
                    }
                    await dispatch(actions.showing.showingRequestStep2Agent(body))
                    navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
                    toast.success('Agent invitation sent!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsLoading(false)
                });
            } catch (error) {
                setIsLoading(false)
                toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }

    }, [form])

    return (
        <PageWrapper>
            <CustomContainer>
                <div className="invite-agent-container">
                    <div className='header'>
                        <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}/${urlParams.get('from')}/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>Cancel</p>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Invite Agent</p>
                        <p style={{ fontSize: 16, fontWeight: 400, color: '#5F5F5F' }}>Please Fill Out the information for your agent.</p>
                    </div>
                    <Separator height={40} />
                    <div className="form-container">
                        <p style={{ fontSize: 14, fontWeight: 700, color: '#2A2A2A', margin: 0, marginBottom: 8, alignSelf: 'flex-start' }}>Basic Information</p>
                        <CustomTextInput
                            label='Email'
                            size='large'
                            inputProps={{
                                type: "email",
                                placeholder: 'Buyer\'s Agent Email Address',
                                onChange: (event) => onChangeInput({ event, type: "email" }),
                            }}
                            style={{ width: '100%' }}
                            error={form.email.error}
                        />
                        <CustomTextInput
                            label='First Name'
                            size='large'
                            inputProps={{
                                type: "text",
                                placeholder: 'Buyer\'s Agent First Name',
                                onChange: (event) => onChangeInput({ event, type: "first_name" }),
                            }}
                            style={{ width: '100%' }}
                            error={form.first_name.error}
                        />
                        <CustomTextInput
                            label='Last Name'
                            size='large'
                            inputProps={{
                                type: "text",
                                placeholder: 'Buyer\'s Agent Last Name',
                                onChange: (event) => onChangeInput({ event, type: "last_name" }),
                            }}
                            style={{ width: '100%' }}
                            error={form.last_name.error}
                        />
                        <CustomTextInput
                            label='Phone Number (Optional)'
                            size='large'
                            inputProps={{
                                type: "tel",
                                maxLength: 10,
                                placeholder: 'Buyer\'s Agent Phone Number',
                                onChange: (event) => onChangeInput({ event, type: "phone_number" }),
                            }}
                            style={{ width: '100%' }}
                            error={form.phone_number.error}
                        />
                    </div>
                    <Separator height={32} />
                    <CustomButton size='large' text={'Next'} style={{ width: 327 }} action={onSubmit} disabled={!form.email.value || !form.first_name.value || !form.last_name.value} loading={isLoading} />
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default InviteAgent;