import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Components 
import RequestStepper from 'components/RequestStepper';
import Footer from 'components/Footer';
import Icons from 'components/Icons';
// Assets
import house1 from 'assets/images/house1.jpg'
import './confirmProperty.scss'
import Seprator from 'components/Separator';
import { checkImageUrl } from 'utils/modules/Handlers';
import ListingStatus from 'components/ListingStatus';

const ConfirmProperty = () => {
    const { id: listingID, mls } = useParams();
    const {public: { listing: data } } = useSelector((state) => state.listing)
    const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({})

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.listing.retrievePublicListing({ mls, id: listingID }))
            await dispatch(actions.agent.fetchBuyerAgents());
            if (user.id && (user.user_type === 'buyer' || user.user_type === 'seller')) {
                const data = await dispatch(actions.buyer.fetchBuyerProfile());
                if (data.length > 0) {
                    setProfile(data[0]);
                }
            }
        }
        init();
    }, [dispatch, listingID])

    const onNext = async() => {
        const body = {
            mls_source: data.mls_source,
            mls_number: data.mls_number,
            listing_id: data.id,
        }
        await dispatch(actions.showing.showingRequestStep1(body)).then(async() => {
            if (profile.default_buyer_agent || myAgents.length > 0) {
                const body = {
                    showing: localStorage.getItem('showingId'),
                    buyer_agent: profile?.default_buyer_agent?.id || myAgents[0].id,
                }
                await dispatch(actions.showing.showingRequestStep2Agent(body))
                navigate(`/request/${mls}/${listingID}/calendar`)
            } else {
                navigate(`/request/${mls}/${listingID}/choose-agent/?next=calendar`)
            }
        })

    }

    return (
        <> 
            <section className="confirm-property-container">
                {
                    data.id &&
                    <>
                        <div className="listing-detail flex flex--column align-items--center justify-content--center">
                            {/* Back & Next */}
                            <div className='back-next-container'> 
                                <div>
                                    <p className='back-button' onClick={() => navigate(`/listings/${mls}/${listingID}`)}>Back</p>
                                    <h1 className="title">Confirm Property</h1>
                                    <button 
                                        className="button medium primary next-button" 
                                        onClick={onNext}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                            <p className="address">{`${data.address}, ${data.city} ${data.state} ${data.zip_code}`}</p>
                            <Seprator height={15} />
                            <p className="listing">Listing #{data.mls_number || data.id}</p>
                            <Seprator height={15} />
                            <ListingStatus color={data.listing_status_color} text={data.listing_status} fit={true} /> 
                            <Seprator height={20} />
                            <img className="preview" src={checkImageUrl(data.primary_photo_url, house1)} alt="" />
                            <Seprator height={30} />
                            {
                                data.agent.full_name &&
                                <div className="badge">
                                    <span>{data.agent.full_name}</span>
                                    <Seprator width={5} />
                                    <Icons.ListingAgentBadge height={14} width={14} />
                                </div>  
                            }
                        </div>
                    </>  
                }
            </section> 
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RequestStepper />
            </div>
            <Footer subfooter /> */}
        </>
    )
}

export default ConfirmProperty;