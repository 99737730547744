import Separator from 'components/Separator';
import './chooseAgent.scss'
import Icons from 'components/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import actions from 'store/actions';
import { toast } from 'react-toastify'
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';

const ChooseAgent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { id: listingID, mls } = useParams();
    const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const noAgent = useCallback(async () => {
        try {
            if (myAgents.length > 0) {
                const body = {
                    showing: localStorage.getItem('showingId'),
                    buyer_agent: myAgents[0].id,
                }
                await dispatch(actions.showing.showingRequestStep2RemoveAgent(body))
                navigate(`/request/${mls}/${listingID}/${urlParams.get('next') === 'null' ? 'calendar' : urlParams.get('next')}`)
            } else {
                navigate(`/request/${mls}/${listingID}/${urlParams.get('next') === 'null' ? 'calendar' : urlParams.get('next')}`)
            }
        } catch (error) {
            console.log(error)
        }

    }, [myAgents])

    const agentChoices = [
        {
            id: 'showing-bee',
            title: 'Showing Bee Agents',
            desc: 'You may choose from our expertly trained agents to assist you all the way on achieving your dream home.',
            icons: <Icons.BuyerAgentBadge width={31} height={31} />,
            action: () => navigate(`/request/${mls}/${listingID}/showingbee-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`),
        },
        {
            id: 'own-agent',
            title: 'Request showing with my own agent',
            desc: 'If you already worked with an agent before, you can choose them to represent you again.',
            icons: <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width={32} height={32} rx={16} fill="#FAC91F" />
                <path
                    d="M16.0005 12.273V11.2786C16.0005 11.0286 15.6967 10.9064 15.5224 11.0842L13.9475 12.634C13.8351 12.7451 13.8351 12.9173 13.9475 13.0284L15.5168 14.5783C15.6967 14.7505 16.0005 14.6283 16.0005 14.3783V13.384C17.8621 13.384 19.3751 14.8783 19.3751 16.717C19.3751 18.2279 18.3458 19.5056 16.951 19.9111C16.7148 19.9778 16.5629 20.2 16.5629 20.4388C16.5629 20.7999 16.9116 21.0832 17.266 20.9777C18.199 20.7084 19.0186 20.1476 19.6017 19.3794C20.1848 18.6113 20.5 17.6771 20.5 16.717C20.5 14.2616 18.4865 12.273 16.0005 12.273ZM12.6258 16.717C12.6258 15.9726 12.8733 15.2838 13.2951 14.7227C13.4638 14.5005 13.4414 14.195 13.2445 13.995C13.0083 13.7617 12.6033 13.7839 12.4008 14.0506C11.9644 14.6245 11.6753 15.2944 11.5585 16.0028C11.4416 16.7113 11.5005 17.4372 11.73 18.1182C11.9594 18.7993 12.3527 19.4152 12.8761 19.9133C13.3995 20.4113 14.0373 20.7765 14.735 20.9777C15.0893 21.0832 15.438 20.7999 15.438 20.4388C15.438 20.2 15.2862 19.9778 15.0499 19.9111C13.6551 19.5056 12.6258 18.2279 12.6258 16.717Z"
                    fill="white"
                />
            </svg>,
            action: () => navigate(`/request/${mls}/${listingID}/select-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`),
        },
        {
            id: 'invite-agent',
            title: 'I’d like to invite an agent',
            desc: 'If you already have an agent that we can work with, please fill out the agent’s contact details and we’ll get things ready for you.',
            icons: <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width={32} height={32} rx={16} fill="#FAC91F" />
                <path
                    d="M19.4286 16.5714H16.5715V19.4286C16.5715 19.7428 16.3143 20 16 20C15.6858 20 15.4286 19.7428 15.4286 19.4286V16.5714H12.5715C12.2572 16.5714 12 16.3143 12 16C12 15.6857 12.2572 15.4286 12.5715 15.4286H15.4286V12.5714C15.4286 12.2571 15.6858 12 16 12C16.3143 12 16.5715 12.2571 16.5715 12.5714V15.4286H19.4286C19.7429 15.4286 20 15.6857 20 16C20 16.3143 19.7429 16.5714 19.4286 16.5714Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5715 16.5714H19.4286C19.7429 16.5714 20 16.3143 20 16C20 15.6857 19.7429 15.4286 19.4286 15.4286H16.5715V12.5714C16.5715 12.2571 16.3143 12 16 12C15.6858 12 15.4286 12.2571 15.4286 12.5714V15.4286H12.5715C12.2572 15.4286 12 15.6857 12 16C12 16.3143 12.2572 16.5714 12.5715 16.5714H15.4286V19.4286C15.4286 19.7428 15.6858 20 16 20C16.3143 20 16.5715 19.7428 16.5715 19.4286V16.5714ZM15.2286 16.7714H12.5715C12.1467 16.7714 11.8 16.4247 11.8 16C11.8 15.5752 12.1467 15.2286 12.5715 15.2286H15.2286V12.5714C15.2286 12.1467 15.5753 11.8 16 11.8C16.4248 11.8 16.7715 12.1467 16.7715 12.5714V15.2286H19.4286C19.8534 15.2286 20.2 15.5752 20.2 16C20.2 16.4247 19.8534 16.7714 19.4286 16.7714H16.7715V19.4286C16.7715 19.8533 16.4248 20.2 16 20.2C15.5753 20.2 15.2286 19.8533 15.2286 19.4286V16.7714Z"
                    fill="white"
                />
            </svg>,
            action: () => navigate(`/request/${mls}/${listingID}/invite-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}&from=choose-agent`),
        },
        {
            id: 'no-agent',
            title: 'I’d like to request without an agent',
            desc: 'Choose this option if you want to proceed your request without an agent.',
            icons: <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width={32} height={32} rx={16} fill="#FAC91F" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.25 16C12.25 15.5858 12.5858 15.25 13 15.25H19C19.4142 15.25 19.75 15.5858 19.75 16C19.75 16.4142 19.4142 16.75 19 16.75H13C12.5858 16.75 12.25 16.4142 12.25 16Z"
                    fill="white"
                />
            </svg>,
            action: noAgent,
        },
    ]

    useEffect(() => {
        const init = async () => {
            await dispatch(actions.agent.fetchBuyerAgents());
        }
        init();
    }, [dispatch, listingID])

    console.log(urlParams.get('prev'))
    const onGoBack = () => {
        if (urlParams.get('prev') === 'null' || !urlParams.get('prev')) {
            navigate(`/listings/${mls}/${listingID}`);
        } else {
            if (urlParams.get('prev') && urlParams.get('prev') !== 'confirm') {
                navigate(`/request/${mls}/${listingID}/${urlParams.get('prev')}/?prev=${urlParams.get('next')}&next=${urlParams.get('next')}${urlParams.get('back') && '&back=' + urlParams.get('back')}`)
            }
        }
    }

    return (
        <PageWrapper>
            <CustomContainer>
                <div className="choose-agent-container">
                    <div className='header'>
                        <p className='back-button' onClick={onGoBack}>Back</p>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Choose Buyer’s Agent</p>
                        <p style={{ fontSize: 16, fontWeight: 400, color: '#5F5F5F' }}>Choose among our Buyer’s Agent that will assist you.</p>
                    </div>
                    <Separator height={40} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}>
                        {
                            agentChoices
                                .filter(agent => !(agent.id === 'own-agent' && myAgents.length === 0))
                                .map((agent) => (
                                    <div className={`agent-choices ${agent.id}`} onClick={agent.action}>
                                        <div>{agent.icons}</div>
                                        <div>
                                            <p style={{ fontSize: 14, fontWeight: 700 }}>{agent.title}</p>
                                            <Separator height={8} />
                                            <p style={{ fontSize: 12, fontWeight: 400, color: '#5F5F5F' }}>{agent.desc}</p>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default ChooseAgent;