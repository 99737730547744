import { Instance } from "utils";

const fetchShowingsAll = ({ offset }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`showing/search/?limit=20&offset=${offset}&duration=all&exclude_draft=true&exclude_cancelled=true`)
}

const fetchShowings = (duration) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`showing/search/?duration=${duration}&is_active=true&exclude_cancelled=true`)
}

const fetchDraftShowings = ({ offset }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`showing/?limit=20&offset=${offset}&duration=all&buyer_drafts_only=true`)
}

const fetchArchivedShowings = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`showing/?limit=20&offset=0&duration=all&archived_only=true`)
}

const searchShowings = ({ offset, filters, duration }) => {
    let url = `?limit=20&offset=${offset}&duration=${duration}&exclude_draft=true`;
    if (filters) {
        url += `&${filters}`
        if (filters.exclude_cancelled) {
            url += `&exclude_cancelled=true`
        }
    }
    return Instance.get(`showing/search/${url}`)
}

const retrieveShowing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`showing/${id}/`)
}

const deleteShowing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`showing/${id}/`)
}

const showingRequestStep1 = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-confirm/`, data)
}

const showingRequestStep2Agent = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-agent/`, data)
}

const showingRequestStep2RemoveAgent = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-remove-agent/`, data)
}

const showingRequestStep2Buyer = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-buyer/`, data)
}

const showingRequestStep3 = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-calendar/`, data)
}

const showingRequestStep4 = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/request-step-review/`, data)
}


const approveShowingBA = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/approve-ba/`)
}

const approveShowingLA = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/approve-la/`)
}

const approveShowingSeller = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/approve-seller/`)
}

const approveShowingForSeller = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/approve-for-seller/`)
}

const rejectShowingLA = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/reject-la/`, data)
}

const rejectShowingSeller = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/reject-seller/`, data)
}

const startShowing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/start/`)
}

const finishShowing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/finish/`)
}

const cancelShowing = ({id, data}) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/cancel/`, data)
}

const approveShowingEmail = ({id, data}) => {
    return Instance.post(`/showing/${id}/approve-by-email/`, data)
}

const rejectShowingEmail = ({id, data}) => {
    return Instance.post(`/showing/${id}/reject-by-email/`, data)
}

const showingFormRequest = (data) => {
    const token = localStorage.getItem('token')
    if (token) {
        Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    }
    return Instance.post(`/showing/request-showing-form/`, data)
}

const draftShowingsTotal = () => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`/showing/draft-showings/`);
}

const requestFeedbackShowing = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post(`/showing/${id}/request-feedback/`)
}

const sendShowingFeedback = (data) => {
    return Instance.post(`/feedback/`, data);
}

const fetchShowingFeedback = (listing) => {
    return Instance.get(`/feedback/?listing=${listing}`);
}

const fetchBuyerShowings = ({ offset, buyer_id }) => {
    let url = `?limit=20&offset=${offset}&duration=current&exclude_draft=true&buyer_id=${buyer_id}`;
    return Instance.get(`showing/buyer-showings/${url}`)
}

const auth = {
    fetchShowingsAll,
    searchShowings,
    fetchShowings,
    fetchDraftShowings,
    fetchArchivedShowings,
    retrieveShowing,
    deleteShowing,
    showingRequestStep1,
    showingRequestStep2Agent,
    showingRequestStep2RemoveAgent,
    showingRequestStep2Buyer,
    showingRequestStep3,
    showingRequestStep4,
    approveShowingBA,
    approveShowingLA,
    approveShowingSeller,
    approveShowingForSeller,
    rejectShowingLA,
    rejectShowingSeller,
    startShowing,
    finishShowing,
    cancelShowing,
    approveShowingEmail,
    rejectShowingEmail,
    showingFormRequest,
    draftShowingsTotal,
    requestFeedbackShowing,
    sendShowingFeedback,
    fetchShowingFeedback,
    fetchBuyerShowings,
}


export default auth;
