import Separator from 'components/Separator';
import './publicCatalog.scss'
import Icons from 'components/Icons';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomContainer from 'components/CustomContainer';
import PageWrapper from 'components/PageWrapper';
import CustomButton from 'components/CustomButton';
import CatalogueContainer from 'components/CatalogueContainer';
import Accordion from 'components/Accordion';
import discoveryCallGraphics from 'assets/images/DiscoveryCallGraphics.svg'
import SuccessModal from 'components/SuccessModal';
import CustomTextInput from 'components/CustomTextInput';
import { inputChange, validateEmail, validatePhone } from 'utils/modules/Handlers';
import { toast } from 'react-toastify';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

const initialForm = {
    name: { value: '', error: '' },
    email: { value: '', error: '' },
    phone_number: { value: '', error: '' },
}

const PublicCatalog = () => {
    const dispatch = useDispatch();
    const tiersRef = useRef(null);
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const accordionItems = [
        {
            title: 'Q. What\'s the difference between a pre-approval letter and a pre-qualification letter?',
            content: 'A pre-qualification letter offers a basic estimate of how much you may be eligible to borrow, based on limited financial details. In contrast, a pre-approval letter involves a more in-depth evaluation, including a credit check and verification of your financial information. This gives you a clearer picture of your borrowing capacity and makes the pre-approval a more credible document when submitting a home offer. Essentially, pre-qualification is an initial step to assess your loan potential, while pre-approval reflects a more solid commitment from the lender based on verified data.'
        },
        {
            title: 'Q. How will it benefit me to be a Queen Bee vs a House Bee?',
            content: 'As a Queen Bee, you\'ll enjoy top-tier benefits, including auto-approval for cash buyers, booking of showings on the LIVE Showing Request calendar, and the exclusive Queen Bee badge visible to listing agents and sellers. This badge instantly signals that you are a highly qualified, ready-to-act buyer, making your request stand out. When agents and sellers see the Queen Bee status on your showing request, they are more likely to prioritize it over others, recognizing the thorough vetting or cash-ready status that comes with this tier. This increased visibility gives you a competitive edge in securing showings and moving forward in the buying process. House Bees also enjoy the benefit of booking live showings on the Showing Bee calendar. A pre-approval letter gives House Bees more credibility than those without one, but it doesn’t match the higher level of qualification and confidence that comes with a buyer who has undergone extensive due diligence by a mortgage loan officer, underwriting, or AI approval. Queen Bees, especially cash buyers, are qualified at the highest level, signaling a much stronger position to agents and sellers.'
        },
        {
            title: 'Q. Do I need a mortgage broker (MLO) to get a loan?',
            content: 'While it\'s possible to apply for a loan directly with a lender, working with a Mortgage Loan Originator (MLO) can significantly simplify the process and help you secure the best loan option for your needs. An MLO gathers key financial information about you, customizes a loan package tailored to your specific situation, and shops multiple lenders to find the most competitive rates. Depending on your eligibility, they can guide you toward different loan types, such as VA, FHA, conventional, or jumbo loans, based on their research and understanding of your financial profile. Additionally, many lenders utilize advanced AI tools to streamline the process, allowing MLOs to quickly analyze your data and ensure you get the most favorable terms. Essentially, an MLO acts as your advocate, simplifying the loan process and helping you navigate the complex world of mortgage options with confidence.'
        },
        {
            title: 'Q: How do I know how much I can afford for a home purchase?',
            content: 'How much you can afford will be based on many factors such as your monthly income, debt you hold and your debt-to-income ratio or "DTI", your credit score, how much down payment you will be putting down. Mortgage calculators can be utilize,  but consulting with a financial advisor or MLO will result in a more accurate assessment of your home buying capacity. Some buyers like to work with Mortgage Brokers who can assist in educating buyers on program options and terms that are available.'
        },
    ];

    const [form, setForm] = useState(
        {
            name: { value: '', error: '' },
            email: { value: '', error: '' },
            phone_number: { value: '', error: '' },
        }
    )

    const scrollToTiers = () => {
        if (tiersRef.current) {
            const yOffset = -80;
            const element = tiersRef.current;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const clearInput = () => {
        setForm(initialForm);

        let name = document.getElementById('name')
        if (name) { name.value = '' }

        let email = document.getElementById('email')
        if (email) { email.value = '' }

        let phone_number = document.getElementById('phone_number')
        if (phone_number) { phone_number.value = '' }
    }

    const onSubmit = useCallback(async () => {
        const { email, name, phone_number } = form
        let isValid = true
        if (name.value === '') {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
        }

        if (!validateEmail(email.value)) {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
        }
        if (!validatePhone(phone_number.value)) {
            inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: '' })
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                const data = {
                    email: email.value,
                    full_name: name.value,
                    phone_number: `1${phone_number.value}`,
                };
                await dispatch(actions.auth.discoveryCallRequest(data));
                toast.success('Request sent successfully!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setModal(false);
                clearInput();
                setLoading(false);
            } catch (error) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false);
            }
        }
    }, [form])


    return (
        <>
            <Header />
            <SuccessModal modal={modal} setModal={setModal}>
                <p style={{ fontSize: 24, fontWeight: 700, margin: 0, color: '#2A2A2A' }}>Thank you for your interest in our services.</p>
                <Separator height={24} />
                <p style={{ fontSize: 14, fontWeight: 400, margin: 0, color: '#2A2A2A' }}>Please send us an email by filling out the form below, and we will send you a schedule for the call.</p>
                <Separator height={40} />
                <CustomTextInput
                    label={'Full Name'}
                    inputProps={{
                        type: 'text',
                        placeholder: 'Name',
                        onChange: (event) => onChangeInput({ event, type: 'name' }),
                    }}
                    style={{ width: '300px' }}
                    size={'large'}
                />
                <Separator height={24} />
                <CustomTextInput
                    label={'Email'}
                    inputProps={{
                        type: 'email',
                        placeholder: 'Email Address',
                        onChange: (event) => onChangeInput({ event, type: 'email' }),
                    }}
                    style={{ width: '300px' }}
                    size={'large Z  '}
                />
                <Separator height={24} />
                <CustomTextInput
                    label={'Phone Number'}
                    inputProps={{
                        type: 'text',
                        placeholder: 'Phone Number',
                        maxLength: 10,
                        onChange: (event) => onChangeInput({ event, type: 'phone_number' }),
                    }}
                    style={{ width: '300px' }}
                    size={'large'}
                />
                <Separator height={24} />
                <CustomButton text={'Send'} style={{ width: '300px' }} size={'large'} action={onSubmit} loading={loading} />
            </SuccessModal>
            <PageWrapper>
                <CustomContainer>
                    <div className="buyer-tiers-container">
                        <div className='buyer-tiers-container-header'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h1 style={{ fontSize: 40, fontWeight: 700, margin: 0, color: '#2A2A2A' }}>Hello there!</h1>
                                <CustomButton text={'See Tiers'} size={'large'} style={{ width: 118 }} action={() => scrollToTiers()} />
                            </div>
                            <Separator height={16} />
                            <p style={{ fontSize: 24, fontWeight: 400, margin: 0, color: '#5f5f5f' }}>Let's choose which type of buyer you are and see which tier fits you.</p>
                            <Separator height={36} />
                            <p className="free-text">
                                And the neat part? It's <span className="highlight">100%</span> <span style={{ color: '#FAC91F', fontSize: 32 }}>FREE</span>
                            </p>
                            <Separator height={40} />
                            <div className="steps-container">
                                <div className="step">
                                    <h2>01</h2>
                                    <h3>Explore Your Options</h3>
                                    <p>Review the Forager, House, and Queen Bee tiers to find which one best fits your current buying status, whether you're just browsing, getting pre-qualified, or fully pre-approved.</p>
                                </div>

                                <div className="step">
                                    <h2>02</h2>
                                    <h3>Sign Up & Upload Documentation</h3>
                                    <p>Once you've selected the tier that suits you, register and upload any necessary documents, such as pre-qualification or pre-approval letters, to access the features. If you need assistance, our mortgage pros are ready to assist!</p>
                                </div>

                                <div className="step">
                                    <h2>03</h2>
                                    <h3>Start Enjoying Your Benefits</h3>
                                    <p>After enrolling in your chosen tier, begin saving your favorite searches, requesting showings, and connecting with agents or sellers. You'll be recognized as a serious buyer based on your tier.</p>
                                </div>
                            </div>
                        </div>
                        <Separator height={60} />
                        <div id='tiers' ref={tiersRef} className='buyer-tiers-container-catalogue'>
                            <CatalogueContainer type='light' style={{ width: 300, height: 670 }}>
                                <div className="tier-card forager">
                                    <div className='tier-card-content'>
                                        <Icons.ForagerBeeTier height={80} width={80} />
                                        <Separator height={32} />
                                        <h2>Forager Bee</h2>
                                        <Separator height={8} />
                                        <p className='sub-title'>Passive</p>
                                        <Separator height={16} />
                                        <p className='description'>Sellers and agents may see you as a looker.</p>
                                        <div className="perk">
                                            <Separator height={16} />
                                            <h3>Documentation</h3>
                                            <Separator height={16} />
                                            <p style={{ fontSize: 14, fontWeight: 700, color: '#5F5F5F', margin: 0 }}>What’s my perks?</p>
                                            <Separator height={12} />
                                            <ul>
                                                <li><Icons.CircleCheck color='#2A2A2A' height={12} width={12} />Save searches and add to favorites</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <CustomButton text={'Get Started'} action={() => navigate('/login')} type={'dark'} style={{ width: '100%' }} />
                                </div>
                            </CatalogueContainer>
                            <CatalogueContainer type='light' style={{ width: 300, height: 670 }}>
                                <div className="tier-card house">
                                    <div className='tier-card-content'>
                                        <Icons.HouseBeeTier height={80} width={80} />
                                        <Separator height={32} />
                                        <h2>House Bee</h2>
                                        <Separator height={8} />
                                        <p className='sub-title'>Basic</p>
                                        <Separator height={16} />
                                        <p className='description'>Sellers and agents may see you as a serious buyer.</p>
                                        <div className="perk">
                                            <Separator height={16} />
                                            <h3>Pre-Qualified</h3>
                                            <Separator height={16} />
                                            <p style={{ fontSize: 14, fontWeight: 700, color: '#5F5F5F', margin: 0 }}>What’s my perks?</p>
                                            <Separator height={12} />
                                            <ul>
                                                <li><Icons.CircleCheck color='#2A2A2A' height={12} width={12} />Upload Pre-Qualification letter</li>
                                                <li><Icons.CircleCheck color='#2A2A2A' height={12} width={12} />Fill out buyer questionnaire</li>
                                                <li><Icons.CircleCheck color='#2A2A2A' height={12} width={12} />Save searches and add to favorites</li>
                                                <li><Icons.CircleCheck color='#2A2A2A' height={12} width={12} />Request Showings via Live Calendar</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <CustomButton text={'Get Started'} action={() => navigate('/login')} type={'dark'} style={{ width: '100%' }} />
                                </div>
                            </CatalogueContainer>
                            <CatalogueContainer type='primary' style={{ width: 300, height: 670 }}>
                                <div className="tier-card queen">
                                    <div className='tier-card-content'>
                                        <Icons.QueenBeeTier height={80} width={80} />
                                        <Separator height={32} />
                                        <h2>Queen Bee</h2>
                                        <Separator height={8} />
                                        <p className='sub-title'>Hive Master</p>
                                        <Separator height={16} />
                                        <p className='description'>Sellers and agents will see you as a ready buyer.</p>
                                        <div className="perk">
                                            <Separator height={16} />
                                            <h3>Pre-Approved</h3>
                                            <Separator height={16} />
                                            <p style={{ fontSize: 14, fontWeight: 700, color: 'white', margin: 0 }}>What’s my perks?</p>
                                            <Separator height={12} />
                                            <ul>
                                                <li><Icons.CircleCheck color='#ffc107' height={12} width={12} />Cash buyers are auto-approved</li>
                                                <li><Icons.CircleCheck color='#ffc107' height={12} width={12} />Upload Pre-Approval letter</li>
                                                <li><Icons.CircleCheck color='#ffc107' height={12} width={12} />Get pre-approved through a verified ShowingBee lender</li>
                                                <li><Icons.CircleCheck color='#ffc107' height={12} width={12} />Save searches and add to favorites</li>
                                                <li><Icons.CircleCheck color='#ffc107' height={12} width={12} />Request Showings via Live Calendar</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <CustomButton text={'Get Started'} action={() => navigate('/login')} type={'tertiary'} style={{ width: '100%' }} />
                                </div>
                            </CatalogueContainer>
                            <CatalogueContainer type='light' style={{ width: 300, height: 670 }}>
                                <div className="tier-card discovery">
                                    <div className='tier-card-content'>
                                        <img src={discoveryCallGraphics} alt='discovery call' />
                                        <Separator height={16} />
                                        <h2>Not sure what to choose?</h2>
                                        <Separator height={16} />
                                        <p style={{ fontSize: 14, fontWeight: 400, color: '#2A2A2A', margin: 0 }}>Feel free to book a quick discovery call to tell us about your needs and we can give you an option that works for you.</p>
                                    </div>
                                    <CustomButton text={'Book a Discovery Call'} type={'dark'} style={{ width: '100%' }} action={() => setModal(prev => !prev)} />
                                </div>
                            </CatalogueContainer>
                        </div>
                        <Separator height={60} />
                        <p style={{ fontSize: 40, fontWeight: 700, margin: 0, color: '#2A2A2A', textAlign: 'center' }}>Have questions? We got answers!</p>
                        <Separator height={60} />
                        <div className='buyer-tiers-container-faq'>
                            <Accordion items={accordionItems} />
                        </div>
                    </div>
                </CustomContainer>
            </PageWrapper>
            <Footer />
        </>
    )
}


export default PublicCatalog;