import * as React from "react";

const ShowingSchedule = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path
            d="M8.228 0.333328H3.5498C1.77325 0.333328 0.333344 1.77323 0.333344 3.54978V10.4405C0.333344 10.9951 0.782942 11.4444 1.33731 11.4444H8.228C10.0043 11.4444 11.4445 10.0045 11.4445 8.22799V3.54978C11.4445 1.77323 10.0043 0.333328 8.228 0.333328ZM7.00259 7.01151C7.00259 7.31762 6.75433 7.56588 6.44823 7.56588H3.11187C2.80576 7.56588 2.5575 7.31762 2.5575 7.01151C2.5575 6.70541 2.80576 6.45715 3.11187 6.45715H6.44823C6.75433 6.45715 7.00259 6.70541 7.00259 7.01151ZM8.66593 5.32682H3.11187C2.80576 5.32682 2.5575 5.07856 2.5575 4.77246C2.5575 4.46636 2.80576 4.2181 3.11187 4.2181H8.66593C8.97204 4.2181 9.2203 4.46636 9.2203 4.77246C9.2203 5.07856 8.97204 5.32682 8.66593 5.32682Z"
            fill="#5F5F5F"
        />
        <path
            d="M11.4333 11.9L11.9 11.4333L10.6667 10.2V8.66667H10V10.4667L11.4333 11.9ZM10.3333 13.6667C9.87222 13.6667 9.43889 13.5791 9.03333 13.404C8.62778 13.2289 8.275 12.9914 7.975 12.6917C7.675 12.3919 7.43756 12.0391 7.26267 11.6333C7.08778 11.2276 7.00022 10.7942 7 10.3333C6.99978 9.87244 7.08733 9.43911 7.26267 9.03333C7.438 8.62756 7.67544 8.27478 7.975 7.975C8.27456 7.67522 8.62733 7.43778 9.03333 7.26267C9.43933 7.08756 9.87267 7 10.3333 7C10.794 7 11.2273 7.08756 11.6333 7.26267C12.0393 7.43778 12.3921 7.67522 12.6917 7.975C12.9912 8.27478 13.2288 8.62756 13.4043 9.03333C13.5799 9.43911 13.6673 9.87244 13.6667 10.3333C13.666 10.7942 13.5784 11.2276 13.404 11.6333C13.2296 12.0391 12.9921 12.3919 12.6917 12.6917C12.3912 12.9914 12.0384 13.229 11.6333 13.4043C11.2282 13.5797 10.7949 13.6671 10.3333 13.6667Z"
            fill="#5F5F5F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6667 8.66667H10V10.4667L11.4333 11.9L11.9 11.4333L10.6667 10.2V8.66667ZM10.3324 14.6667C9.74136 14.6665 9.17284 14.5535 8.63693 14.3221C8.12042 14.0991 7.66111 13.7917 7.26815 13.399C6.87483 13.006 6.56722 12.5463 6.34433 12.0291C6.11332 11.4931 6.00029 10.9247 6 10.3338C5.99972 9.74228 6.11287 9.17319 6.3447 8.63668C6.56786 8.12022 6.87513 7.66095 7.26763 7.26816C7.66051 6.87499 8.12019 6.56746 8.63729 6.34443C9.1736 6.11312 9.74228 6 10.3333 6C10.9244 6 11.4931 6.11312 12.0294 6.34443C12.5465 6.56746 13.0062 6.87499 13.399 7.26816C13.7914 7.66085 14.0988 8.11998 14.3221 8.63626C14.5544 9.17318 14.6675 9.74276 14.6667 10.3348C14.6658 10.925 14.5529 11.4928 14.3227 12.0283C14.1001 12.5461 13.7921 13.0063 13.398 13.3996C13.005 13.7916 12.5462 14.0989 12.0305 14.3221C11.4943 14.5541 10.9242 14.6671 10.3324 14.6667ZM10.3333 13.6667C9.87222 13.6667 9.43889 13.5791 9.03333 13.404C8.62778 13.2289 8.275 12.9914 7.975 12.6917C7.675 12.3919 7.43756 12.0391 7.26267 11.6333C7.08778 11.2276 7.00022 10.7942 7 10.3333C6.99978 9.87244 7.08733 9.43911 7.26267 9.03333C7.438 8.62756 7.67544 8.27478 7.975 7.975C8.27456 7.67522 8.62733 7.43778 9.03333 7.26267C9.43933 7.08756 9.87267 7 10.3333 7C10.794 7 11.2273 7.08756 11.6333 7.26267C12.0393 7.43778 12.3921 7.67522 12.6917 7.975C12.9912 8.27478 13.2288 8.62756 13.4043 9.03333C13.5799 9.43911 13.6673 9.87244 13.6667 10.3333C13.666 10.7942 13.5784 11.2276 13.404 11.6333C13.2296 12.0391 12.9921 12.3919 12.6917 12.6917C12.3912 12.9914 12.0384 13.229 11.6333 13.4043C11.2282 13.5797 10.7949 13.6671 10.3333 13.6667Z"
            fill="white"
        />
    </svg>
);


export default ShowingSchedule;
