// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card-component {
  border-radius: 16px;
  background: white;
  box-shadow: 0px 5px 20px 0px rgba(42, 42, 42, 0.1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 310px;
  width: 248px;
  justify-content: space-between;
}
.profile-card-component .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-card-component .content p {
  margin: 0;
}
.profile-card-component .content > img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: cover;
}
.profile-card-component .content .badge {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 7px 10px;
  column-gap: 10px;
}
.profile-card-component .content .badge.broker {
  background: #E7EEE6;
}
.profile-card-component .content .badge.la {
  background: #FEF7DE;
}
.profile-card-component .content .badge.ba {
  background: #FEF2F2;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileCard/profileCard.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,iBAAA;EACA,kDAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AAER;AADQ;EACI,SAAA;AAGZ;AADQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAGZ;AADQ;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAGZ;AAFY;EACI,mBAAA;AAIhB;AAFY;EACI,mBAAA;AAIhB;AAFY;EACI,mBAAA;AAIhB","sourcesContent":[".profile-card-component {\n    border-radius: 16px;\n    background: white;\n    box-shadow: 0px 5px 20px 0px rgba(42, 42, 42, 0.10);\n    padding: 24px;\n    display: flex;\n    flex-direction: column;\n    height: 310px;\n    width: 248px;\n    justify-content: space-between;\n    .content {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        p {\n            margin: 0;\n        }\n        > img {\n            width: 80px;\n            height: 80px;\n            border-radius: 100%;\n            object-fit: cover;\n        }\n        .badge {\n            width: 100%;\n            height: 58px;\n            display: flex;\n            align-items: center;\n            border-radius: 10px;\n            padding: 7px 10px;\n            column-gap: 10px;\n            &.broker {\n                background: #E7EEE6;\n            }\n            &.la {\n                background: #FEF7DE;\n            }\n            &.ba {\n                background: #FEF2F2;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
