import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Packages
import '@splidejs/react-splide/css/core';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';

// Assets
import './selectAgent.scss';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ProfileCard from 'components/ProfileCard';
import CustomButton from 'components/CustomButton';

const SelectAgent = () => {
    const { id: listingID, mls } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const { showing } = useSelector((state) => state.showing)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const id = localStorage.getItem("showingId")
                await dispatch(actions.showing.retrieveShowing(id))
                await dispatch(actions.agent.fetchBuyerAgents())
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error)
            }
        }
        init();
    }, [dispatch])

    const onSelectAgent = async (agent, showing) => {
        const body = {
            showing: showing.id,
            buyer_agent: agent.id,
        }
        await dispatch(actions.showing.showingRequestStep2Agent(body))
        navigate(`/request/${mls}/${listingID}/${urlParams.get('next')}`)
    }


    return (
        <PageWrapper>
            <CustomContainer>
                <div className="featured-agents-container">
                    <div className='header'>
                        <p className='back-button' onClick={() => navigate(`/request/${mls}/${listingID}/choose-agent/?prev=${urlParams.get('prev')}&next=${urlParams.get('next')}`)}>Cancel</p>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Recent Agents</p>
                        <p style={{ fontSize: 16, fontWeight: 400, color: '#5F5F5F' }}>Here are the agents you worked with before.</p>
                    </div>
                    <div className="agents">
                        {
                            !isLoading
                                ? <div className='contents'>
                                    {
                                        myAgents.length > 0
                                            ? <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: myAgents.length > 3 ? 4 : 3 }}
                                                style={{ padding: '0 58px' }}
                                            >
                                                <Masonry gutter={'24px'}>
                                                    {
                                                        myAgents.map((agent, index) => (
                                                            <ProfileCard data={agent} type='ba' key={index}>
                                                                <CustomButton
                                                                    text='Select Agent'
                                                                    style={{ width: '100%', alignSelf: 'center' }}
                                                                    type='secondary'
                                                                    size='small'
                                                                    action={() => onSelectAgent(agent, showing)}
                                                                />
                                                            </ProfileCard>


                                                        ))
                                                    }
                                                </Masonry>
                                            </ResponsiveMasonry>
                                            : <p className='no-result'>No Agents Available</p>
                                    }
                                </div>
                                : <div className='contents'>
                                    <div className="loader">
                                        <Icons.Loading background={'none'} height={100} width={100} />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default SelectAgent;