import { useState } from 'react'
// Redux
import { useNavigate } from 'react-router-dom';
// Utils
import { inputChange } from 'utils/modules/Handlers';
// Modules
import SelectUserType from './modules/SelectUserType';
import InformationForm from './modules/InformationForm';
import AccountForm from './modules/AccountForm';
// Assets
import './signup.scss'
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import Logo from 'components/Logo';

const Signup  = ({ modalSignup = false, setSelectedForm }) => {
    const navigate = useNavigate()
    const [active, setActive] = useState('info')
    const [form, setForm] = useState({
        first_name: {
            value: '',
            error: '',
        },
        last_name: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        password1: {
            value: '',
            error: '',
        },
        password2: {
            value: '',
            error: '',
        },
        phone_number: {
            value: '',
            error: '',
        },
        user_type: {
            value: '',
        },
    });

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const handleLogin = () => {
        if (modalSignup) {
            setSelectedForm('login')
        } else {
            navigate('/login')
        }
    }

    return (
        <PageWrapper>
            <CustomContainer>
                <div className={`signup-container ${modalSignup ? 'modal' : ''}`}>
                    {
                        !modalSignup &&
                        <Logo style={{ height: 32 }}  action={() => navigate('/')} />
                    }
                    <div className='form'>
                        {
                            active === 'info' &&
                            <InformationForm
                                onChangeInput={onChangeInput}
                                form={form}
                                setForm={setForm}
                                setActive ={setActive}
                            />
                        }

                        {
                            active === 'account' &&
                            <AccountForm 
                                onChangeInput={onChangeInput}
                                form={form}
                                setForm={setForm}
                                setActive ={setActive}
                            />
                        }
                        
                        {
                            active === 'user_type' &&
                            <SelectUserType 
                                form={form}
                                setForm={setForm}
                                modalSignup={modalSignup}
                            />
                        }
                        <p className='sign-up'>Already have an account? <span onClick={handleLogin}>Sign in here</span></p>
                    </div>
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default Signup;