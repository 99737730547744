import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
// Utils
import { checkImageUrl, formatCurrency, getErrorMessage } from 'utils/modules/Handlers';
// Packages
import Talk from 'talkjs';
import moment from 'moment';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
// Assets
import './requestReview.scss';
import house1 from 'assets/images/house1.jpg';
import NoUser from 'assets/images/no_user.jpg';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import html2canvas from 'html2canvas';
import { Config } from 'utils';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import ListingStatus from 'components/ListingStatus';
import CustomButton from 'components/CustomButton';
import SuccessModal from 'components/SuccessModal';

const RequestReview = () => {
    const { id: showingID, mls } = useParams();
    const { user } = useSelector((state) => state.auth);
    // const { buyerAgents: myAgents } = useSelector((state) => state.agent)
    const urlParams = new URLSearchParams(window.location.search);
    const { showing } = useSelector((state) => state.showing)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [talkLoaded, markTalkLoaded] = useState(false);
    const [relatedUsers, setRelatedUsers] = useState([])
    const [attachment, setAttachment] = useState(null)
    const [modal, setModal] = useState(false)


    useEffect(() => {
        const initSBBot = async () => {
            Talk.ready.then(() => markTalkLoaded(true));
            if (talkLoaded) {
                const showingBeeBot = {
                    name: 'Showing Bee Showings Bot',
                    email: ['showings@showingbee.com'],
                    photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                    role: 'admin',
                }
                await dispatch(actions.message.updateCreateUser({ id: 'showingbee_showings_bot', data: showingBeeBot }));
            }
        }

        initSBBot();
    }, [talkLoaded])


    useEffect(() => {
        const initShowingData = async () => {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id));
        }
        initShowingData();
    }, [])


    useEffect(() => {
        const initRelatedUsers = async () => {
            if (showing.id) {
                let users = [];
                users.push(user);
                if (showing.buyer_agent_data) {
                    users.push(showing.buyer_agent_data?.agent_data.user_data)
                }
                users.push(showing.listing_data?.agent_data.user_data);
                if (showing.agent_members_data && showing.agent_members_data.length > 0) {
                    showing.agent_members_data.map(item => {
                        users.push(item.agent_data.user_data);
                    })
                }
                console.log(users)
                setRelatedUsers(users)
            }
        }

        initRelatedUsers();
    }, [showingID, showing, user])

    useEffect(() => {
        const getAttachment = () => {
            if (showing.id) {
                let preview = document.getElementById('showing-preview')
                html2canvas(preview, { useCORS: true, scale: 2 }).then(async (canvas) => {
                    console.log(canvas.toDataURL());
                    canvas.toBlob(async (blob) => {
                        const formData = new FormData()
                        const file = new File([blob], `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`, { type: "image/png" })
                        formData.append('file', file);
                        formData.append('filename ', `showing_request_${showing.id}__${moment(new Date(showing.date)).format('MM-DD-YY')}.png`);
                        const response = await dispatch(actions.message.uploadFiles({ data: formData }));
                        setAttachment(response.attachmentToken)
                    }, 'image/png')
                });
            }

        }
        getAttachment();
    }, [showing, showingID])

    const getDate = (data) => {
        const date = moment(data).format('MMM DD, YYYY | dddd')
        return date
    }

    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            if (attachment) {
                const messageData = [
                    {
                        text: `Showing Request for Listing #${showing.listing_data?.mls_number} on ${showing.date} around ${showing.start_time} to ${showing.finish_time}`,
                        sender: 'showingbee_showings_bot',
                        type: "SystemMessage",
                    },
                    {
                        attachmentToken: attachment,
                        type: 'UserMessage',
                        sender: 'showingbee_showings_bot',
                    },
                    {
                        text: `Listing Details: ${Config.CLIENT_URL}/listings/${showing.listing_data.mls_source}/${showing.listing_data.mls_number || showing.listing_data.id}/`,
                        type: 'UserMessage',
                        sender: 'showingbee_showings_bot',
                    },
                ]

                const showingBeeBot = new Talk.User({
                    id: 'showingbee_showings_bot',
                    name: 'Showing Bee Showings Bot',
                    photoUrl: 'https://i.ibb.co/SdMNTMM/sb-bot.png',
                    email: 'showings@showingbee.com',
                    role: 'admin',
                });

                await Promise.all(
                    relatedUsers.map(async (item) => {
                        const userData = new Talk.User({
                            id: item.id,
                            name: `${item.first_name} ${item.last_name}`,
                            email: item.email,
                            photoUrl: checkImageUrl(item.profile_photo, NoUser),
                            role: item.user_type,
                        });

                        const session = new Talk.Session({
                            appId: Config.TalkJS_APP_ID,
                            me: userData,
                        });
                        let conversationId = Talk.oneOnOneId(userData, showingBeeBot);
                        const conversation = session.getOrCreateConversation(conversationId)
                        conversation.setParticipant(userData, { access: "Read", notify: true });
                        conversation.setParticipant(showingBeeBot, { access: "Read", notify: true });

                        try {
                            await dispatch(actions.message.updateCreateUser({
                                id: userData.id,
                                data: {
                                    name: userData.name,
                                    email: [userData.email],
                                    photoUrl: userData.photoUrl,
                                    role: userData.role,
                                }
                            }));
                            await dispatch(actions.message.createConversation({ id: conversation.id, data: { participants: [userData.id.toString(), showingBeeBot.id.toString()] } }));
                        } catch (error) {
                            console.log(error);
                        }
                        await dispatch(actions.message.sendMessage({ id: conversation.id, data: messageData }))
                    })
                ).then(async () => {
                    await dispatch(actions.showing.showingRequestStep4({ showing: showing.id }))
                    setLoading(false);
                    setModal(prev => !prev);
                })

            }
        } catch (error) {
            setLoading(false);
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [relatedUsers, attachment, showing])

    const onChangeAgent = () => {
        navigate(`/request/${mls}/${showingID}/choose-agent/?prev=review&next=review${urlParams.get('back') ? '&back=' + urlParams.get('back') : ''}`)
    }

    const addAgent = () => {
        navigate(`/request/${mls}/${showingID}/choose-agent/?prev=review&next=review`)
    }

    const onBack = () => {
        if (urlParams.get('back')) {
            navigate(`/${urlParams.get('back')}?active=drafts`)
        } else {
            navigate(`/request/${mls}/${showingID}/calendar`)
        }
    }

    return (
        <>
            <SuccessModal
                modal={modal}
                setModal={setModal}
                closeButton={false}
            >
                <h1 style={{ margin: 0,  fontSize: '32px', fontWeight: 700}}>Showing Request Submitted!</h1>
                <Separator height={24} />
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 400, color: '#949494', width: 550, textAlign: 'center', lineHeight: '24px' }}>Thank you! Your request has been submitted. Please allow for some time for your showing request to be approved.</p>
                <Separator height={32} />
                <CustomButton text={'Browse More Listings'} size={'large'} action={() => navigate('/listings/')} />
            </SuccessModal>
            <PageWrapper>
                <CustomContainer>
                    {
                        showing.id &&
                        <div className="request-review-container">
                            <div className='header'>
                                <p className='back-button' onClick={onBack}>Back</p>
                                <p style={{ fontSize: 20, fontWeight: 700 }}>Showing Request Review</p>
                                <p style={{ fontSize: 16, fontWeight: 400, color: '#5F5F5F' }}>Please review the details of your showing request.</p>
                            </div>
                            <Separator height={24} />
                            <div className='contents-container'>
                                <div className='contents'>
                                    <img id='preview' className="preview" src={checkImageUrl(showing.listing_data?.featured_image?.image_url, house1)} alt="#" />
                                    <Separator height={16} />
                                    <ListingStatus color={showing.listing_data?.listing_status_color} text={showing.listing_data?.listing_status} />
                                    <Separator height={16} />
                                    <div className='content-details'>
                                        <div className='listing-details'>
                                            <p className="listing">Listing #{showing.listing_data?.mls_number}</p>
                                            <p className="title">{showing.listing_data?.property_type} in {showing.listing_data?.city}</p>
                                            <p className="address">
                                                {`${showing.listing_data?.address}, ${showing.listing_data?.city} ${showing.listing_data?.state} ${showing.listing_data?.zip_code}`}
                                            </p>
                                        </div>
                                        <Separator height={16} />
                                        <p style={{ fontSize: 20, fontWeight: 400, color: '#2A2A2A', margin: 0 }}>{showing.listing_data?.price ? formatCurrency(showing.listing_data?.price) : 'Price Not Indicated'}</p>
                                        <Separator height={24} />
                                        {
                                            showing.listing_data?.agent &&
                                            <div className='user-info'>
                                                <Icons.ListingAgentIndicator height={32} width={32} />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{showing.listing_data?.list_agent_full_name}</p>
                                                    <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.listing_data?.list_agent_email}</span>
                                                </div>
                                            </div>
                                        }
                                        <Separator height={24} />
                                        {
                                            showing.buyers_data?.length > 0 &&
                                            showing.buyers_data.map((item, index) => {
                                                return (
                                                    <div className='user-info' key={index}>
                                                        <Icons.BuyerIndicator height={32} width={32} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{item.first_name} {item.last_name}</p>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{item.email}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Separator height={24} />
                                        <div className="info-container">
                                            <div className="details">
                                                <div className='showing'>
                                                    <p className="date">{getDate(showing.date)}</p>
                                                    <p className='address'>{`${showing.listing_data?.address}, ${showing.listing_data?.city} ${showing.listing_data?.state} ${showing.listing_data?.zip_code}`}</p>
                                                    <p className="specific">{`${showing.start_time} - ${showing.finish_time}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Separator height={24} />
                                        <div style={{ displat: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {
                                                    showing.buyer_agent_data &&
                                                    <div className='user-info'>
                                                        <Icons.BuyerAgentIndicator height={32} width={32} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{showing.buyer_agent_data?.first_name} {showing.buyer_agent_data?.last_name}</p>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.buyer_agent_data?.email}</span>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.buyer_agent_data?.phone_number}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    showing.buyer_agent_data ?
                                                        <CustomButton text={'Change'} action={onChangeAgent} type={'secondary'} />
                                                        :
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                            <CustomButton text={'Add Buyer\'s Agent'} action={addAgent} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <Separator height={32} />
                                        <CustomButton text={'Send Request'} action={onSubmit} loading={loading} disabled={!attachment} style={{ width: '100%' }} size={'large'} />
                                    </div>
                                </div>

                                <div id='showing-preview' className='contents' style={{ padding: '0px 40px 20px 40px' }}>
                                    {/* <img id='preview' className="preview" src={checkImageUrl(showing.listing_data?.featured_image?.image_url, house1)} alt="#" /> */}
                                    <Icons.SBHeader width={400} />
                                    <ListingStatus color={showing.listing_data?.listing_status_color} text={showing.listing_data?.listing_status} />
                                    <Separator height={16} />
                                    <div className='content-details'>
                                        <div className='listing-details'>
                                            <p className="listing">Listing #{showing.listing_data?.mls_number}</p>
                                            <p className="title">{showing.listing_data?.property_type} in {showing.listing_data?.city}</p>
                                            <p className="address">
                                                {`${showing.listing_data?.address}, ${showing.listing_data?.city} ${showing.listing_data?.state} ${showing.listing_data?.zip_code}`}
                                            </p>
                                        </div>
                                        <Separator height={16} />
                                        <p style={{ fontSize: 20, fontWeight: 400, color: '#2A2A2A', margin: 0 }}>{showing.listing_data?.price ? formatCurrency(showing.listing_data?.price) : 'Price Not Indicated'}</p>
                                        <Separator height={24} />
                                        {
                                            showing.listing_data?.agent &&
                                            <div className='user-info'>
                                                <Icons.ListingAgentIndicator height={32} width={32} />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{showing.listing_data?.list_agent_full_name}</p>
                                                    <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.listing_data?.list_agent_email}</span>
                                                </div>
                                            </div>
                                        }
                                        <Separator height={24} />
                                        {
                                            showing.buyers_data?.length > 0 &&
                                            showing.buyers_data.map((item, index) => {
                                                return (
                                                    <div className='user-info' key={index}>
                                                        <Icons.BuyerIndicator height={32} width={32} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{item.first_name} {item.last_name}</p>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{item.email}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Separator height={24} />
                                        <div className="info-container">
                                            <div className="details">
                                                <div className='showing'>
                                                    <p className="date">{getDate(showing.date)}</p>
                                                    <p className='address'>{`${showing.listing_data?.address}, ${showing.listing_data?.city} ${showing.listing_data?.state} ${showing.listing_data?.zip_code}`}</p>
                                                    <p className="specific">{`${showing.start_time} - ${showing.finish_time}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Separator height={24} />
                                        <div style={{ displat: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {
                                                    showing.buyer_agent_data &&
                                                    <div className='user-info'>
                                                        <Icons.BuyerAgentIndicator height={32} width={32} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <p style={{ fontSize: 16, fontWeight: 700, color: '#2A2A2A', margin: 0 }}>{showing.buyer_agent_data?.first_name} {showing.buyer_agent_data?.last_name}</p>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.buyer_agent_data?.email}</span>
                                                            <span style={{ fontSize: 14, fontWeight: 400, color: '#949494', margin: 0 }}>{showing.buyer_agent_data?.phone_number}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </CustomContainer>
            </PageWrapper>
        </>
    )
}


export default RequestReview;