import Separator from 'components/Separator'
import './MLSFooterPage.scss'
import { useDispatch, useSelector } from 'react-redux';
import Header from 'components/Header';
import Footer from 'components/Footer';
import placeholder1 from 'assets/images/placeholder1.png'
import SubHeroImage from 'assets/images/SubHeroImage.png'
import Fred from 'assets/images/Fred.png'
import Erin from 'assets/images/Erin.png'
import Keith from 'assets/images/Keith.png'
import Mik from 'assets/images/Mik.png'
import Marco from 'assets/images/Marco.png'
import ListingSearchWebMockup from 'assets/images/ListingSearchWebMockup.png'
import { inputChange, validateEmail, validatePhone } from 'utils/modules/Handlers';
import { useCallback, useState } from 'react';
import Seprator from 'components/Separator';
import Icons from 'components/Icons';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';
import CustomTextInput from 'components/CustomTextInput';
import CustomButton from 'components/CustomButton';
import HeaderNavigation from 'components/HeaderNavigation';


const initialForm = {
    first_name: {
        value: '',
        error: '',
    },
    last_name: {
        value: '',
        error: '',
    },
    email: {
        value: '',
        error: '',
    },
    phone_number: {
        value: '',
    },
    position: {
        value: '',
        error: '',
    },
    mls_name: {
        value: '',
        error: '',
    },
}

const MLSFooterPage = () => {
    const { user } = useSelector((state) => state.auth);
    const [form, setForm] = useState(initialForm);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const highlights = [
        {
            title: 'MLS Solution',
            desc: 'A superior alternative for agents and MLSs, Showing Bee aims to be the go-to platform for efficient and seamless property showings. Unlike other showing apps available, our platform is free to MLS clients and provides a reliable user-friendly interface that agents will love.'
        },
        {
            title: 'Approval and Launch',
            desc: 'Derived from our robust desktop app, our mobile app has secured approval from both Apple and Android, with the final version scheduled for release in January 2025.'
        },
        {
            title: 'User Experience',
            desc: 'Showing Bee distinguishes itself with an intuitive interface, providing an enhanced showing experience for real estate professionals, buyers, and sellers.'
        }
    ]

    const team_members = [
        {
            image: Fred,
            name: 'John Frederick Buenafe',
            position: 'Front End Developer / UI',
        },
        {
            image: Erin,
            name: 'Erin Knorr',
            position: 'Creator / Shareholder',
        },
        {
            image: Keith,
            name: 'Keith Gordon',
            position: 'Creator / Shareholder / Real Estate Broker',
        },
        {
            image: Mik,
            name: 'Mik Goldwyn Medina',
            position: 'Back End Software Engineer',
        },
        {
            image: Marco,
            name: 'Marco Pregonero',
            position: 'UI / UX Designer',
        },
    ]

    const onChangeInput = ({ event, type }) => {
        const data = event.target.value;
        inputChange({ setForm: setForm, key: type, value: 'value', data: data })
    }

    const onSubmit = useCallback(async () => {
        const { email, first_name, last_name, phone_number, position, mls_name } = form
        let isValid = true
        if (!user.id) {
            if (first_name.value === '') {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: 'First name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'first_name', value: 'error', data: '' })
            }

            if (last_name.value === '') {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: 'Last name cannot be blank' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'last_name', value: 'error', data: '' })
            }

            if (!validateEmail(email.value)) {
                inputChange({ setForm: setForm, key: 'email', value: 'error', data: 'Entered email address is invalid' })
                isValid = false
            } else {
                inputChange({ setForm: setForm, key: 'email', value: 'error', data: '' })
            }
            if (phone_number.value) {
                if (!validatePhone(phone_number.value)) {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: 'Please enter the right 10-digit phone number' })
                    isValid = false
                } else {
                    inputChange({ setForm: setForm, key: 'phone_number', value: 'error', data: '' })
                    isValid = true
                }
            }
        }

        if (position.value === '') {
            inputChange({ setForm: setForm, key: 'position', value: 'error', data: 'Position cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'position', value: 'error', data: '' })
            isValid = true
        }

        if (mls_name.value === '') {
            inputChange({ setForm: setForm, key: 'mls_name', value: 'error', data: 'MLS Name cannot be blank' })
            isValid = false
        } else {
            inputChange({ setForm: setForm, key: 'mls_name', value: 'error', data: '' })
            isValid = true
        }

        if (isValid) {
            setLoading(true);
            try {
                let dataForm = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    phone: phone_number.value,
                    position: position.value,
                    mls_name: mls_name.value,
                };

                await dispatch(actions.auth.demoRequest(dataForm)).then(() => {
                    toast.success('Request for a Demo Sent!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false);
                });

            } catch (error) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false);
            }
            clearInput();
        }
    }, [form, user])

    const clearInput = () => {
        setForm(initialForm);

        let first_name = document.getElementById('first_name')
        if (first_name) { first_name.value = '' }

        let last_name = document.getElementById('last_name')
        if (last_name) { last_name.value = '' }

        let email = document.getElementById('email')
        if (email) { email.value = '' }

        let phone_number = document.getElementById('phone_number')
        if (phone_number) { phone_number.value = '' }

        let position = document.getElementById('position')
        if (position) { position.value = '' }

        let mls_name = document.getElementById('mls_name')
        if (mls_name) { mls_name.value = '' }
    }

    return (
        <>
            {
                !user.id ? <Header darkmode /> : <Header />
            }
            <Separator height={80} />
            <PageWrapper>
                <CustomContainer>
                    <div className="mls-detail-page-container">
                        <div className='content'>
                            <div className='top-header'>
                                <img src={ListingSearchWebMockup} className='hero-banner' alt='Showing Bee Search Listing' />
                                <div className='about'>
                                    <img src={SubHeroImage} className='subhero-banner' alt='Showing Bee Mobile Search Listing' />
                                    <div className='about'><p>ABOUT<br /> SHOWING BEE</p></div>
                                    <div className='desc'>
                                        <span>
                                            Far more than just a showing app, Showing Bee is set to redefine how real estate showings are managed and experienced. Notably, our platform not only competes with the current industry leader, ShowingTime (owned by Zillow), but surpasses it in both user interface and functionality.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Separator height={100} />
                            <div className='highlights'>
                                <p className='top-header'>KEY HIGHLIGHTS</p>
                                <Separator height={30} />
                                <h2 className='header'>Charting a Path to Success</h2>
                                <Separator height={50} />
                                <div className='highlight'>
                                    {
                                        highlights.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className='number'>{index + 1}</p>
                                                    <p className='title'>{item.title}</p>
                                                    <span className='desc'>{item.desc}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Separator height={120} />
                            <div className='market'>
                                <img src={placeholder1} className='image' alt='Market landscape' />
                                <div className='details'>
                                    <p className='header'>Market<br /> Landscape</p>
                                    <span className='body'>Unlike previous attempts to challenge ShowingTime, Showing Bee stands out with its comprehensive approach. We are a listing aggregator, a showing service, a platform for sellers to promote their property's details through a unique social media component called "Waggle," named after the dance bees do to attract other bees to the hive. Moreover, we leverage AI to assist buyers in various aspects, including mortgage applications, positioning us as a multifaceted solution in the real estate tech landscape.</span>
                                </div>
                            </div>
                            <Separator height={120} />
                            <div className='team-container'>
                                <p className='header'>The Team</p>
                                <Separator height={40} />
                                <span className='subheader'>Our success is not just attributed to our cutting-edge product but also to our experienced team. Erin and I, with a background in real estate software development since 2005, have a proven track record of navigating complex challenges. Complemented by our core developers, the Showing Bee team, currently consisting of 5 members, is set to evolve into an agile and proficient development team of 10. This expansion ensures the delivery of a first-class service and product, commencing in the first quarter of 2025</span>
                                <Separator height={80} />
                                <div className='team'>
                                    {
                                        team_members.map((member, index) => {
                                            return (
                                                <div className='member' key={index}>
                                                    <img className='member-profile' src={member.image} alt={member.name} />
                                                    <div className='member-detail'>
                                                        <p>{member.name}</p>
                                                        <span>{member.position}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <Separator height={120} />
                                <div className='get-intouch'>
                                    <div className='form'>
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, alignItems: 'center' }}>
                                            <p className='header'>Schedule a Demo</p>
                                            <span className='subheader'>
                                                Reach out to engage with us and explore how you can be part of the Showing Bee success story. Please fill out the form below to request a demo. Please include your position and MLS.
                                            </span>
                                            <Separator height={40} />
                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: 'John',
                                                    id: 'first_name',
                                                    value: form.first_name.value,
                                                    onChange: (event) => onChangeInput({ event, type: "first_name" }),
                                                }}
                                                label={'First Name'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.first_name.error}
                                            />

                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: 'Doe',
                                                    id: 'last_name',
                                                    value: form.last_name.value,
                                                    onChange: (event) => onChangeInput({ event, type: "last_name" }),
                                                }}
                                                label={'Last Name'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.last_name.error}
                                            />

                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: '10-digit Phone Number',
                                                    id: 'phone_number',
                                                    value: form.phone_number.value,
                                                    onChange: (event) => onChangeInput({ event, type: "phone_number" }),
                                                }}
                                                label={'Phone Number (Optional)'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.phone_number.error}
                                            />

                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: 'example@email.com',
                                                    id: 'email',
                                                    value: form.email.value,
                                                    onChange: (event) => onChangeInput({ event, type: "email" }),
                                                }}
                                                label={'Email Address'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.email.error}
                                            />
                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: 'Sales Director',
                                                    id: 'position',
                                                    value: form.position.value,
                                                    onChange: (event) => onChangeInput({ event, type: "position" }),
                                                }}
                                                label={'Position'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.position.error}
                                            />
                                            <CustomTextInput
                                                inputProps={{
                                                    type: "text",
                                                    placeholder: 'Multiple Listing Service',
                                                    id: 'mls_name',
                                                    value: form.mls_name.value,
                                                    onChange: (event) => onChangeInput({ event, type: "mls_name" }),
                                                }}
                                                label={'MLS Name'}
                                                size={'large'}
                                                style={{ width: 300 }}
                                                error={form.mls_name.error}
                                            />
                                            <CustomButton text={'Schedule Now'} style={{ width: 300 }} size={'large'} loading={loading} action={onSubmit} />
                                            <Separator height={40} />
                                            <span className='subheader' style={{ fontSize: 14, fontWeight: 700, color: '#2a2a2a' }}>Thank you for considering Showing Bee. We look forward to the opportunity of collaborating with you.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </PageWrapper>
            <Separator height={100} />
            <Footer />
        </>
    )
}


export default MLSFooterPage;