import Separator from 'components/Separator';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// Packages
import Splide from '@splidejs/splide';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import './beeBrokers.scss';
import { getErrorMessage } from 'utils/modules/Handlers';
import ToastMessage from 'components/ToastMessage';
import ProfileCard from 'components/ProfileCard';
import CustomButton from 'components/CustomButton';
import PageWrapper from 'components/PageWrapper';
import CustomContainer from 'components/CustomContainer';

const BeeBrokers = () => {
    const { tier } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const { featuredBrokers } = useSelector((state) => state.broker)


    useEffect(() => {
        const init = () => {
            new Splide('#featured-brokers', {
                type: featuredBrokers.length < 3 ? 'slide' : 'loop',
                rewind: true,
                pagination: false,
                arrows: true,
                perPage: featuredBrokers.length < 3 ? featuredBrokers.length : 3,
                focus: 'left',
                fixedWidth: 250,
            }).mount();
            setLoad(true);
        }

        if (!load) {
            init();
        }

    }, [featuredBrokers])

    useEffect(() => {
        const init = async () => {
            await dispatch(actions.broker.fetchFeaturedMortgageBrokers())
        }
        init();
    }, [dispatch])

    const onSelect = async (broker, showing) => {
        const data = {
            mortgage_broker_id: broker.id,
            buyer_tier: tier === 'house' ? 'H' : 'Q',
            request_upgrade: true,
        }
        try {
            await dispatch(actions.buyer.inviteMortgageBroker(data));
            await dispatch(actions.auth.retrieveUser());
            toast.success('Upgrade request successfully sent!', {
                position: toast.POSITION.TOP_CENTER
            });
            navigate('/bee-tier');
        } catch (error) {
            toast.error(<ToastMessage messages={getErrorMessage(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return (
        <PageWrapper>
            <CustomContainer>
                <div className="featured-mortgage-container">
                    <p className='back-button' onClick={() => navigate(`/bee-tier/upgrade/${tier}`)}>Back</p>
                    <Separator height={80} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
                        <p style={{ fontSize: '24px', fontWeight: '700', margin: '0', color: '#2A2A2A' }}>Featured Mortgage Brokers</p>
                        <p style={{ fontSize: '16px', fontWeight: '400', margin: '0', color: '#5F5F5F' }}>Choose among our Mortgage Brokers that will assist you.</p>
                    </div>
                    <Separator height={40} />
                    <div className='contents'>
                        <div id="featured-brokers" className="splide agent-slider">
                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.0607 5.93934C15.6464 6.52513 15.6464 7.47487 15.0607 8.06066L11.1213 12L15.0607 15.9393C15.6464 16.5251 15.6464 17.4749 15.0607 18.0607C14.4749 18.6464 13.5251 18.6464 12.9393 18.0607L7.93934 13.0607C7.35355 12.4749 7.35355 11.5251 7.93934 10.9393L12.9393 5.93934C13.5251 5.35355 14.4749 5.35355 15.0607 5.93934Z"
                                            fill="#5F5F5F"
                                        />
                                    </svg>
                                </button>
                                <button className="splide__arrow splide__arrow--next">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.93934 5.93934C9.52513 5.35355 10.4749 5.35355 11.0607 5.93934L16.0607 10.9393C16.6464 11.5251 16.6464 12.4749 16.0607 13.0607L11.0607 18.0607C10.4749 18.6464 9.52513 18.6464 8.93934 18.0607C8.35355 17.4749 8.35355 16.5251 8.93934 15.9393L12.8787 12L8.93934 8.06066C8.35355 7.47487 8.35355 6.52513 8.93934 5.93934Z"
                                            fill="#5F5F5F"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="splide__track">
                                <ul className="splide__list">
                                    {
                                        featuredBrokers.map(broker => (
                                            <li key={broker.id} className="splide__slide">
                                                <ProfileCard data={broker} type='broker'>
                                                    <CustomButton
                                                        text='Select Mortgage Broker'
                                                        style={{ width: '100%' }}
                                                        type='secondary'
                                                        size='small'
                                                        action={() => onSelect(broker)}
                                                    />
                                                </ProfileCard>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Separator height={100} />
                </div>
            </CustomContainer>
        </PageWrapper>
    )
}


export default BeeBrokers;