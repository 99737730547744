import { Instance } from "utils";

const postWaggle = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('post/', data)
}

const fetchWaggles = ({ offset }) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.get(`post/?ordering=-date_created&offset=${offset}&limit=10`)
}

const deleteWaggle = (id) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.delete(`post/${id}/`)
}

const postMedia = (data) => {
    const token = localStorage.getItem('token')
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('media/', data)
}

const fetchListingWaggles = ({ offset, listing }) => {
    return Instance.get(`post/?listing=${listing}&ordering=-date_created&offset=${offset}`)
}

const fetchUsergWaggles = ({ offset, user }) => {
    return Instance.get(`post/?user=${user}&ordering=-date_created&offset=${offset}`)
}

const checkNewWaggles = (data) => {
    const token = localStorage.getItem('token');
    Instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    return Instance.post('post/check-new-waggles/', data);
};

const auth = {
    postWaggle,
    postMedia,
    fetchWaggles,
    fetchListingWaggles,
    fetchUsergWaggles,
    checkNewWaggles,
    deleteWaggle
}


export default auth;
