// Utils
import { getErrorMessageSignup, inputChange } from 'utils/modules/Handlers';
// Components
import Icons from 'components/Icons';
import Separator from 'components/Separator';
// Assets
import './selectUserType.scss'
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage';
import CustomButton from 'components/CustomButton';
import { useNavigate } from 'react-router-dom';

const SelectUserType = ({ form, setForm, modalSignup }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const onTypeSelect = (data) => {
        inputChange({ setForm: setForm, key: 'user_type', value: 'value', data: data })
    }


    const onNext = useCallback(async (event) => {
        const { email, first_name, last_name, phone_number, password1, password2, user_type } = form
        setIsLoading(true);
        console.log('here')
        try {
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                is_staff: false,
                password1: password1.value,
                password2: password2.value,
                timezone: 'America/New_York',
                user_type: (user_type.value === 'LA' || user_type.value === 'BA') ? 'agent' : user_type.value === 'MB' ? 'mortgage_broker' : 'buyer',
            }
            if (phone_number.value) {
                data.phone_number = phone_number.value
            }
            await dispatch(actions.auth.signup(data)).then(async() => {
                await dispatch(actions.auth.login({ email: email.value, password: password1.value }));
                setIsLoading(false);
                dispatch(actions.auth.retrieveUser());
                if (!modalSignup) {
                    navigate("/");
                }
                toast.success(`Welcome to Showing Bee ${first_name.value}!`, { position: toast.POSITION.TOP_CENTER });
            });

        } catch (error) {
            setIsLoading(false);
            toast.error(<ToastMessage messages={getErrorMessageSignup(error.response.data)} />, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, [form])


    return (
        <div className="select-user-type-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p className='header'>Hello, {form.first_name.value}! <br />
                We are excited to have you here</p>
            <span className='subheader'>Fill in your details and we'll set you up quickly</span>
            <Separator height={40} />
            <ul>
                <li>
                    <div className={`choice ${form.user_type.value === 'Buyer' && 'selected'}`} onClick={() => onTypeSelect('Buyer')}>
                        <div className='check'></div>
                        <Icons.QueenBeeTier height={60} width={60} />
                        <p>Buyer or Seller</p>
                    </div>
                </li>
                <li>
                    <div className={`choice ${form.user_type.value === 'BA' && 'selected'}`} onClick={() => onTypeSelect('BA')}>
                        <div className='check'></div>
                        <Icons.BuyerAgentBadge height={60} width={60} />
                        <p>Buyer's Agent</p>
                    </div>
                </li>
                <li>
                    <div className={`choice ${form.user_type.value === 'LA' && 'selected'}`} onClick={() => onTypeSelect('LA')}>
                        <div className='check'></div>
                        <Icons.ListingAgentBadge height={60} width={60} />
                        <p>Listing Agent</p>
                    </div>
                </li>
                <li>
                    <div className={`choice ${form.user_type.value === 'MB' && 'selected'}`} onClick={() => onTypeSelect('MB')}>
                        <div className='check'></div>
                        <Icons.BrokerBadge height={60} width={60} />
                        <p>Mortgage Broker</p>
                    </div>
                </li>
            </ul>
            <Separator height={40} />
            <CustomButton text={'Get Started'} style={{ width: 300 }} size={'large'} loading={isLoading} action={onNext} />
        </div>
    )
}


export default SelectUserType;