import Icons from 'components/Icons';
import { checkImageUrl, formatCurrency } from 'utils/modules/Handlers';
import './requestListingtDetails.scss'
import house1 from 'assets/images/house1.jpg'
import NoUser from 'assets/images/no_user.jpg'
import Seprator from 'components/Separator';
import ListingStatus from 'components/ListingStatus';
import CustomButton from 'components/CustomButton';
import { useCallback, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import actions from 'store/actions';


const RequestListingtDetails = ({ listing, currentShowing, timezone }) => {
    const { showing } = useSelector((state) => state.showing);
    const { mls, id: listingID } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const onNext = useCallback(async () => {
        const start = moment(currentShowing.start).tz(timezone, true);
        const finish = moment(currentShowing.end).tz(timezone, true);
        const body = {
            showing: showing.id,
            start,
            finish,
        }

        setLoading(true)
        try {
            await dispatch(actions.showing.showingRequestStep3(body))
            navigate(`/request/${mls}/${listingID}/review`)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }, [currentShowing, timezone])

    return (
        <div className="request-listing-details">
            <div className='header'>Showing Bee Calendar</div>
            <div className='body'>
                {
                    listing?.id ?
                        <div className='showing-details'>
                            <p className='title'>Showing: Listing {listing.mls_number || listing.id}</p>
                            <Seprator height={16} />
                            <img src={listing?.primary_photo_url ? checkImageUrl(listing?.primary_photo_url, house1) : house1} alt="" />
                            <div className="content">
                                <Seprator height={16} />
                                <ListingStatus color={listing?.listing_status_color} text={listing?.listing_status} fit={true} />
                                <Seprator height={20} />
                                <span className="listing-number">Listing #{listing?.mls_number || listing.id}</span>
                                <Seprator height={8} />
                                <p className="listing-name">{listing?.property_type} in {listing?.city}</p>
                                <Seprator height={8} />
                                <p className="listing-address">
                                    {`${listing?.address}, ${listing?.city} ${listing?.state} ${listing?.zip_code}`}
                                </p>
                                <Seprator height={16} />
                                <Seprator height={20} />
                                <ul className="listing-amenities">
                                    <li className="item">
                                        <Icons.Bed height={16} width={16} />
                                        <p className="p20">{listing?.bedroom_count} Bedrooms</p>
                                    </li>
                                    <li className="item">
                                        <Icons.Bath height={16} width={16} />
                                        <p className="p20">{listing?.bathroom_count} Baths</p>
                                    </li>
                                    <li className="item">
                                        <Icons.Car height={16} width={16} />
                                        <p className="p20">{listing?.garage_spaces} Garage</p>
                                    </li>
                                    <li className="item">
                                        <Icons.Lot height={11} width={11} />
                                        <p className="p20">{listing?.square_footage} Sq. Ft.</p>
                                    </li>
                                </ul>
                                <Seprator height={24} />
                                <p className="listing-price">{formatCurrency(listing?.price)}</p>                         
                            </div>
                        </div>
                        : <p className='empty'>Loading listing details...</p>
                }
                <div className='schedule-action'>
                    <p style={{ fontSize: 12, fontWeight: 600, color: '#CAA00F', padding: 16, backgroundColor: '#FEF7DE', borderRadius: 10, lineHeight: '20px' }}>
                         {
                            !currentShowing.start || !currentShowing.end ?
                            'Set a schedule for your appointment by dragging on the calendar on the right.'
                            : 'You can drag and drop the schedule to change the day and time. You can also resize the schedule to fit your needs.'
                         }
                    </p>
                    <CustomButton style={{ width: '100%' }} text={'Schedule'} size={'large'} action={onNext} disabled={!currentShowing.start || !currentShowing.end} loading={loading} />
                </div>
            </div>
        </div>
    )
}

export default RequestListingtDetails