import { useEffect, useState } from 'react';
import './calendar.scss'
import CalendarView from './modules/CalendarView';
import PageWrapper from 'components/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from 'store/actions';
import RequestListingtDetails from './modules/RequestListingtDetails';

const Calendar = () => {
    const { id: listingID, mls } = useParams();
    const [currentShowing, setCurrentShowing] = useState({});
    const [openDetails, setOpenDetails] = useState(false);
    const [timezone, setTimezone] = useState('America/New_York')
    const {public: { listing: data } } = useSelector((state) => state.listing)
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async() => {
            await dispatch(actions.listing.retrievePublicListing({ mls, id: listingID }))
        }
        init();
    }, [])

    return (
        <PageWrapper>
            <div className="request-calendar-container">
                <div className="grid">
                    <div className={`details ${openDetails ? 'show' : 'hide'}`}>
                        <RequestListingtDetails 
                            listing={data}
                            currentShowing={currentShowing}
                            timezone={timezone}
                        />
                    </div>
                    <div className='calendar'>
                        <CalendarView
                            currentShowing={currentShowing}
                            setCurrentShowing={setCurrentShowing}
                            timezone={timezone}
                            setTimezone={setTimezone}
                        />
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}


export default Calendar;