import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import actions from 'store/actions'
// Packages
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from 'moment'
import 'moment-timezone';
import { toast } from 'react-toastify'
// Components
import Seprator from 'components/Separator'
// Assets
import './calendarView.scss'
import house1 from 'assets/images/house1.jpg'
import Icons from 'components/Icons'
import { checkImageUrl } from 'utils/modules/Handlers'
import ModalContainer from 'components/ModalContainer'
import CustomSelect from 'components/CustomSelect'
import Loading from 'components/Icons/modules/Loading'

const CalendarView = ({ currentShowing, setCurrentShowing, timezone, setTimezone }) => {
    const { id: listingID, mls } = useParams();
    const { user } = useSelector((state) => state.auth);
    const { showing } = useSelector((state) => state.showing)
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [constraints, setConstraints] = useState([]);
    const calendarRef = useRef();
    const [loading, setLoading] = useState(false)



    const [selectedDate, setSelectedDate] = useState(moment().tz(timezone))
    const [selectedView, setSelectedView] = useState('timeGridWeek')

    const [width, setWidth] = useState(window.innerWidth);
    const [startTime, setStartTime] = useState(moment().startOf('day').format('HH:mm:ss'))
    const [endTime, setEndTime] = useState(moment().endOf('day').format('HH:mm:ss'))
    const [selectedDraft, setSelectedDraft] = useState(null);
    const [modal, setModal] = useState(false);
    const [years, setYears] = useState([]);

    const months = [
        { text: 'January', value: 0 },
        { text: 'February', value: 1 },
        { text: 'March', value: 2 },
        { text: 'April', value: 3 },
        { text: 'May', value: 4 },
        { text: 'June', value: 5 },
        { text: 'July', value: 6 },
        { text: 'August', value: 7 },
        { text: 'September', value: 8 },
        { text: 'October', value: 9 },
        { text: 'November', value: 10 },
        { text: 'December', value: 11 },
    ]

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    useEffect(() => {
        const init = () => {
            const currentYear = 2030;
            let zone = "America/New_York"
            let years = [];
            let startYear = 1980;

            if (user.timezone) { zone = user.timezone }
            while (startYear <= currentYear) {
                const value = startYear++
                years.push({ text: value, value: value });
            }
            setYears(years)
            setTimezone(zone)
            setSelectedDate(moment().tz(zone))

            if (width <= 768) {
                setSelectedView('timeGridDay')
            } else {
                setSelectedView('timeGridWeek')
            }
        }
        init();
    }, [])
    
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const fecthShowing = async () => {
        setLoading(true);
        try {
            const id = localStorage.getItem("showingId")
            await dispatch(actions.showing.retrieveShowing(id))
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fecthShowing();
    }, [])

    useEffect(() => {
        if (showing.id && showing.listing_data) {
            const start = moment().startOf('day').add(showing.listing_data?.availability_start_minutes, 'minute').format('HH:mm:ss');
            const end = moment().startOf('day').add(showing.listing_data?.availability_end_minutes, 'minute').format('HH:mm:ss');
            setStartTime(start)
            setEndTime(end)
        }
    }, [showing]);

    useEffect(() => {
        setLoading(true);
        try {
            if (showing.listing_data) {
                setTimezone(showing.listing_data.timezone)
                const initialConstraints = [{
                    start: moment().tz(timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                    end: roundDate(moment().tz(timezone), 'minutes', 15).format('YYYY-MM-DDTHH:mm:ss'),
                    overlap: false,
                    display: 'background',
                    color: '#888',
                }];
                if (showing.listing_data.effective_notice_required_minutes) {
                    initialConstraints.push({
                        start: roundDate(moment().tz(timezone), 'minutes', 15).format('YYYY-MM-DDTHH:mm:ss'),
                        end: roundDate(moment().tz(timezone), 'minutes', 15).add({ minutes: showing.listing_data.effective_notice_required_minutes }).format('YYYY-MM-DDTHH:mm:ss'),
                        overlap: false,
                        classNames: ['event', 'blackout'],
                        title: 'Blackout',
                        event_type: 'blackout',
                    })
                }
                setConstraints(initialConstraints);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    }, [showing, timezone]);

    useEffect(() => {
        const init = async () => {
            if (showing.listing) {
                setLoading(true);
                try {
                    await dispatch(actions.listing.fetchListingEvents(showing.listing)).then((items) => {
                        const newCurrentShowing = items.find(event => event.id && event.id === showing.id);
                        if (newCurrentShowing) {
                            setCurrentShowing({ ...formatEvent({ ...newCurrentShowing, state: 'pending' }), id: newCurrentShowing.id, editable: true, title: 'showing (current)', classNames: ['event', 'showing-pending', 'current'] });
                        }
                        setLoading(false);
                    })

                } catch (error) {
                    setLoading(false);
                }
            }
        }
        init();
    }, [showing]);

    const handleSelect = useCallback((event) => {
        setCurrentShowing({
            id: "1",
            editable: true,
            start: event.start,
            end: event.end,
            title: 'showing (current)',
            classNames: ['event', 'showing-pending', 'current'],
        });
    }, []);

    const handleSelectAllow = useCallback((selectInfo) => {
        const check = moment(selectInfo.start).format('yyyy-MM-DD');
        const today = moment().subtract(1, 'days').format('yyyy-MM-DD');
        return !(
            showing.listing_data.max_showing_window_minutes !== null &&
            (selectInfo.end - selectInfo.start) > (showing.listing_data.max_showing_window_minutes * 60 * 1000)
        ) && (check > today);
    }, [showing]);

    const fetchListingEvents = useCallback(async (info, success, failure) => {
        const data = await dispatch(actions.listing.fetchListingEventsStartEnd({ listing: showing.listing, start: info.startStr, end: info.endStr }));
        const firstRecurrenceExcluded = data
            .filter(event => !event.occurrences_url) // exclude the original event in favor of first occurrence
            .filter(event => event.id || !data.find(other => other.id && other.url === event.url)); // exclude the default occurrence in favor of the custom recurrence
        const selfExcluded = firstRecurrenceExcluded.filter(event => !event.id || event.id !== showing.id);
        success([...selfExcluded.map(formatEvent), ...constraints, ...[currentShowing].filter(x => x)]);
    }, [constraints, currentShowing, showing]);

    const roundDate = (date, type, offset) => {
        let val = date[type]();
        let roundedVal = Math.ceil((val) / offset) * offset;
        return date[type](roundedVal);
    };

    const formatEvent = (oldEvent) => {
        const event = {
            showing: oldEvent.id,
            title: oldEvent.event_type.replace('_', ' '),
            start: moment.parseZone(oldEvent.start).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment.parseZone(oldEvent.end).local(true).format('YYYY-MM-DDTHH:mm:ss'),
            state: oldEvent.state,
            classNames: ['event'],
            event_type: oldEvent.event_type
        };

        if (!!event.listing_event) {
            event.classNames.push('occurrence');
            if (event.linked_to_parent) {
                event.classNames.push('linkedToParent');
            }
        }
        event.overlap = false;
        if (oldEvent.event_type === 'showing' && oldEvent.state !== 'approved') {
            if (oldEvent.state === 'embryo') {
                event.classNames.push('showing-draft');
            } else {
                event.classNames.push('showing-pending');
            }
            // event.overlap = true;
        } else {
            event.classNames.push(oldEvent.event_type);
        }
        if (!!oldEvent['listing_event']) {
            event.classNames.push('occurrence');
        }
        event.classNames.push(oldEvent.status);

        return event;
    };

    // const onNext = useCallback(async () => {
    //     const start = moment(currentShowing.start).tz(timezone, true);
    //     const finish = moment(currentShowing.end).tz(timezone, true);
    //     const body = {
    //         showing: showing.id,
    //         start,
    //         finish,
    //     }
    //     await dispatch(actions.showing.showingRequestStep3(body))
    //     navigate(`/request/${mls}/${listingID}/review`)

    // }, [currentShowing, timezone])

    // const onBack = () => {
    //     if (urlParams.get('back')) {
    //         navigate(`/${urlParams.get('back')}?active=drafts`)
    //     } else {
    //         if (urlParams.get('prev') && urlParams.get('prev') !== 'confirm') {
    //             navigate(`/request/${mls}/${listingID}/${urlParams.get('prev')}/?prev=${urlParams.get('next')}&next=${urlParams.get('next')}`)
    //         } else {
    //             navigate(`/listings/${mls}/${listingID}`);
    //             toast.info('Showing request progress saved as draft.', {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     }
    // }

    const onShowingDelete = async ({ event, item }) => {
        event.stopPropagation();
        if (item?.state === 'embryo') {
            setLoading(true);
            try {
                await dispatch(actions.showing.deleteShowing(item.showing))
                toast.success('Draft showing has been deleted.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }

    const confirmDraftShowing = useCallback(() => {
        if (selectedDraft?.event.extendedProps?.state === 'embryo') {
            const start = moment(selectedDraft?.event.start).format('hh:mm a')
            const end = moment(selectedDraft?.event.end).format('hh:mm a')
            const message = `Showing Draft (${moment(selectedDraft?.event.start).format('MMMM DD')}, ${start} - ${end}) has been selected.`
            localStorage.setItem('showingId', selectedDraft?.event.extendedProps.showing);
            setModal(prev => !prev);
            fecthShowing();
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }, [selectedDraft])

    const onChangeView = (value) => {
        setSelectedView(value)
        calendarRef.current?.getApi().changeView(value)
    }

    const onMonthChange = (value) => {
        const newDate = moment().set('month', value)
        setSelectedDate(newDate);
        calendarRef.current?.getApi().changeView(selectedView, newDate.format('YYYY-MM-DD'))
    }

    const onYearChange = (value) => {
        const newDate = moment().set('year', value || moment().year())
        setSelectedDate(newDate);
        calendarRef.current?.getApi().changeView(selectedView, newDate.format('YYYY-MM-DD'))
    }

    const dayCellClassNames = (date) => {
        if (date.isPast) {
            return ['past-cell'];
        }
    }

    const renderEvent = (info) => {
        const handleDraftEventSelect = (info) => {
            const props = info.event.extendedProps;
            if (props.event_type === 'showing' && props.state === 'embryo') {
                if (moment(info.event.start).isSameOrAfter(moment())) {
                    setSelectedDraft(info);
                    setModal(prev => !prev);
                }
                else {
                    toast.info('Cannot select draft showing past the current date.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        }
        console.log(info.event)

        return (
            <>
                <div className='event-container' onClick={() => handleDraftEventSelect(info)}>
                    <div className='event-bubble'>
                        <p className='time'>{info.timeText}</p>
                        <span className='detail'>
                            {
                                info.event.extendedProps.event_type !== 'blackout'
                                    ? <span className='detail'>
                                        {
                                            info.event.extendedProps?.state === 'embryo'
                                                ? 'Showing (Draft)'
                                                : info.event.extendedProps?.state === 'approved'
                                                    ? 'Showing (Approved)'
                                                    : (info.event.classNames?.includes('showing-pending') && info.event.classNames?.includes('current'))
                                                        ? 'Showing (Current)'
                                                        : info.event.classNames?.includes('showing-pending')
                                                            ? 'Showing (Pending)'
                                                            : info.event.title}
                                    </span>
                                    : <span className='detail'>Blackout</span>
                            }
                        </span>

                    </div>
                    {
                        (info.event.extendedProps?.state === 'embryo' && info.event.extendedProps.event_type !== 'blackout') &&
                        <div className='actions' onClick={(event) => event.preventDefault()}>
                            <Icons.CircleClose color={'#FF3535'} height={15} className="delete" onClick={(event) => onShowingDelete({ event, item: info.event.extendedProps })} />
                        </div>
                    }

                </div>
                {/* 
                <div className='event-container' onClick={() => handleDraftEventSelect(info)}>
                    <div className='event-bubble'>
                        {
                            info.event.extendedProps.event_type !== 'blackout' &&
                            <p className='time'>{info.timeText}</p>
                        }
                        {
                            info.event.extendedProps.event_type !== 'blackout'
                                ? <span className='detail'>
                                    {
                                        info.event.extendedProps?.state === 'embryo'
                                            ? 'Showing (Draft)'
                                            : info.event.extendedProps?.state === 'approved'
                                                ? 'Showing (Approved)'
                                                : (info.event.classNames?.includes('showing-pending') && info.event.classNames?.includes('current'))
                                                    ? 'Showing (Current)'
                                                    : info.event.classNames?.includes('showing-pending')
                                                        ? 'Showing (Pending)'
                                                        : info.event.title}
                                </span>
                                : <span className='detail'>Blackout</span>
                        }
                    </div>
                    {
                        (info.event.extendedProps?.state === 'embryo' && info.event.extendedProps.event_type !== 'blackout') &&
                        <div className='actions' onClick={(event) => event.preventDefault()}>
                            <Icons.CircleClose color={'#FF3535'} height={15} className="delete" onClick={(event) => onShowingDelete({ event, item: info.event.extendedProps })} />
                        </div>
                    }
                </div> */}
            </>
        )
    }

    const onNext = useCallback(() => {
        let type = 'months';
        if (selectedView === 'timeGridWeek') { type = 'weeks' }
        if (selectedView === 'timeGridDay') { type = 'days' }
        const newDate = moment(selectedDate.format('YYYY-MM-DD')).add(1, type);
        setSelectedDate(newDate);
        calendarRef.current?.getApi().changeView(selectedView, newDate.format('YYYY-MM-DD'))
    }, [selectedDate, selectedView])

    const onPrev = useCallback(() => {
        let type = 'months';
        if (selectedView === 'timeGridWeek') { type = 'weeks' }
        if (selectedView === 'timeGridDay') { type = 'days' }
        const newDate = moment(selectedDate.format('YYYY-MM-DD')).subtract(1, type);
        setSelectedDate(newDate);
        calendarRef.current?.getApi().changeView(selectedView, newDate.format('YYYY-MM-DD'))
    }, [selectedDate, selectedView])

    return (
        <div className="showing-calendar-container">
            {/* <small>Timezone: {timezone}</small> */}
            <ModalContainer modal={modal} setModal={setModal} title={'Load Draft Showing'}>
                <p>You are about to load a <b>Draft Showing Request {`(${moment(selectedDraft?.event.start).format('MMMM DD')}, ${moment(selectedDraft?.event.start).format('hh:mm a')} - ${moment(selectedDraft?.event.end).format('hh:mm a')})`}</b>, if you confirm this action the calendar will load the drafted showing request and it will continue from there.</p>
                <Seprator height={20} />
                <button className='button primary no-glow small' style={{ width: '100%' }} onClick={confirmDraftShowing}>Confirm Action</button>
            </ModalContainer>
            {
                timezone &&
                <div className="calendars">
                    <div className='custom-toolbar'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={onPrev}
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.0607 5.93934C15.6464 6.52513 15.6464 7.47487 15.0607 8.06066L11.1213 12L15.0607 15.9393C15.6464 16.5251 15.6464 17.4749 15.0607 18.0607C14.4749 18.6464 13.5251 18.6464 12.9393 18.0607L7.93934 13.0607C7.35355 12.4749 7.35355 11.5251 7.93934 10.9393L12.9393 5.93934C13.5251 5.35355 14.4749 5.35355 15.0607 5.93934Z"
                                    fill="#5F5F5F"
                                />
                            </svg>
                            <Seprator width={8} />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={onNext}
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.93934 5.93934C9.52513 5.35355 10.4749 5.35355 11.0607 5.93934L16.0607 10.9393C16.6464 11.5251 16.6464 12.4749 16.0607 13.0607L11.0607 18.0607C10.4749 18.6464 9.52513 18.6464 8.93934 18.0607C8.35355 17.4749 8.35355 16.5251 8.93934 15.9393L12.8787 12L8.93934 8.06066C8.35355 7.47487 8.35355 6.52513 8.93934 5.93934Z"
                                    fill="#5F5F5F"
                                />
                            </svg>
                            <Seprator width={25} />
                            <p className='title'>
                                {
                                    selectedView === 'timeGridDay'
                                        ? selectedDate.format('MMMM DD, YYYY')
                                        : selectedDate.format('MMMM YYYY')
                                }
                            </p>
                        </div>
                        <div style={{ display: 'flex', columnGap: 16 }}>
                            <CustomSelect
                                inputProps={{ placeholder: selectedDate.format('MMMM'), id: 'months' }} size={'small'}
                                onChange={(value) => { onMonthChange(value) }}
                                options={months}
                                type='secondary'
                                style={{ width: 120, borderRadius: 6 }}
                            />
                            {
                                width > 768 &&
                                <CustomSelect
                                    inputProps={{ placeholder: selectedDate.format('YYYY'), id: 'years' }} size={'small'}
                                    onChange={(value) => { onYearChange(value) }}
                                    options={years}
                                    type='secondary'
                                    style={{ width: 70, borderRadius: 6 }}
                                />
                            }
                            <ul className='views'>
                                <li className={`${selectedView === 'dayGridMonth' && 'active'}`} onClick={() => onChangeView('dayGridMonth')}>Month</li>
                                <li className={`${selectedView === 'timeGridWeek' && 'active'}`} onClick={() => onChangeView('timeGridWeek')}>Week</li>
                                <li className={`${selectedView === 'timeGridDay' && 'active'}`} onClick={() => onChangeView('timeGridDay')}>Day</li>
                            </ul>
                        </div>
                    </div>
                    <div className="sb-fc">
                        {
                            loading &&
                            <div className='loading'>
                                <Loading background={'transparent'} />
                            </div>
                        }
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
                            height="100%"
                            dayMaxEventRows={true}
                            dayCellClassNames={dayCellClassNames}
                            views={{
                                dayGridMonth: {
                                    dayMaxEventRows: 3,
                                },
                                timeGridWeek: {
                                    eventMaxStack: 1,
                                },
                            }}
                            initialView={selectedView}
                            headerToolbar={false}
                            eventContent={renderEvent}
                            selectable={true}
                            allDaySlot={false}
                            firstDay={1}
                            slotDuration="00:30:00"
                            slotLabelInterval="01:00"
                            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                            eventTimeFormat={{ hour: 'numeric', minute: '2-digit', omitZSeroMinute: false, meridiem: 'lowercase' }}
                            slotMinTime={startTime}
                            slotMaxTime={endTime}
                            slotEventOverlap={false}
                            nowIndicator={true}
                            now={moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
                            longPressDelay={500}
                            loading={(isLoading) => { setLoading(isLoading) }}
                            initialDate={selectedDate?.format('YYYY-MM-DD')}
                            businessHours={[
                                {
                                    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                                    startTime: startTime,
                                    endTime: endTime,
                                },
                            ]}
                            events={fetchListingEvents}
                            selectOverlap={false}
                            select={handleSelect}
                            selectAllow={handleSelectAllow}
                            eventAllow={handleSelectAllow}
                            eventDrop={(info) => handleSelect(info.event)}
                            eventResize={(info) => handleSelect(info.event)}
                        // selectOverlap={(event) => event.classNames.includes('showing-pending')}
                        />
                    </div>
                </div>
            }
        </div>
    );
}


export default CalendarView;